<h1 mat-dialog-title *transloco="let t" class="title">{{ t('user_association_confirmation_page-title') }}</h1>
<div class="main-div">
    <mat-card class="filter-card">
        <mat-card-content>
            <div [innerHTML]="message"></div>
            <br><br>
            <div [innerHTML]="customers"></div>
            <br><br>
            <div [innerHTML]="detail"></div>
            <br><br>
            <div [innerHTML]="currentCustomer"></div>
            <br><br>
            <div [innerHTML]="question"></div>
        </mat-card-content>        
        <mat-card-content class="actionButtons">
            <button mat-raised-button color="primary" type="button" class="navButton" [mat-dialog-close]="true">
                <span  *transloco="let t">{{ t('user_association_confirmation_page-yes_button') }}</span>
            </button>&nbsp;
            <button mat-raised-button color="default" type="button" class="navButton" [mat-dialog-close]="false">
                <span  *transloco="let t">{{ t('user_association_confirmation_page-no_button') }}</span>
            </button>            
        </mat-card-content>
    </mat-card>
</div>