import { Component,Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentService } from 'src/app/shared/payment/services/payment.service';
import { Invoice } from 'src/app/shared/payment/interfaces/Invoice.model';

@Component({
  selector: 'app-invoice-resubmit-confirmation',
  templateUrl: './invoice-resubmit-confirmation.component.html',
  styleUrls: ['./invoice-resubmit-confirmation.component.scss']
})
export class InvoiceResubmitConfirmationComponent implements OnInit {

  loading: boolean = false;

  constructor(public dialogRef: MatDialogRef<InvoiceResubmitConfirmationComponent>,     
    @Inject(MAT_DIALOG_DATA)
    public data: Invoice,
    private paymentService: PaymentService,
    private errorHandler: ErrorHandlerService,    
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
  }

  ResubmitOrder(){
    this.loading = true;
    this.paymentService.ResubmitFailedOrder(this.data.invoiceId)
    .subscribe({
      next: result => {
        this.data.erpOrderNumber = result.erpOrderNumber;
        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.handleError(err);
        this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'));
      }
    });
  }

}
