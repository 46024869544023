<div class="main-div">
	<form>
        <div>
            <div class="resubmitOracleOrderFormSection">
                <div class="information-section">
                    <div>
                        <label *transloco="let t" class="resubmitOracleOrderFormSectionTitle">{{t('confirm_erp_order_resubmit_page-title')}}</label>
                    </div>
                    <br>
                    <div class="information-details">
                        <div class="information-segment">
                            <label *transloco="let t">{{t('confirm_erp_order_resubmit_page-confirmation_message')}}</label>
                        </div>
                    </div>
                    <br>
                    <div class="information-details" *ngIf="newOrderNumberMessage != ''">
                        <div class="information-segment">
                            <label>{{newOrderNumberMessage}}</label>
                        </div>
                    </div>                    
                </div>
            </div>
            <br>
            <div class="actionButtonsSection" *transloco="let t">
                <button mat-raised-button color="primary" type="button" class="navButton" (click)="ResubmitOrder()" [disabled]="loading || disableResubmitButton">
                    <mat-icon class="toolbar-icon">arrow_upward</mat-icon>&nbsp;
                    <span  *transloco="let t">{{ t('confirm_erp_order_resubmit_page-resubmit_button') }}</span>
                </button>&nbsp;
                <button mat-raised-button color="default" type="button" class="navButton" (click)="CloseForm();" [disabled]="loading">
                    <mat-icon class="toolbar-icon">close</mat-icon>&nbsp;
                    <span  *transloco="let t">{{ t('confirm_erp_order_resubmit_page-close_button') }}</span>
                </button>    
            </div>
            <br>
            <div *ngIf="loading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </form>
</div>
