import { Component,Inject, OnInit } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-user-association-confirmation',
  templateUrl: './user-association-confirmation.component.html',
  styleUrls: ['./user-association-confirmation.component.scss']
})
export class UserAssociationConfirmationComponent implements OnInit {

  message: string = "";
  question: string = "";
  detail: string = "";
  currentCustomer: string = "";
  customers: string = "";

  constructor(public dialogRef: MatDialogRef<UserAssociationConfirmationComponent>,     
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private translocoService: TranslocoService,) { }

  ngOnInit(): void {
    this.message = this.translocoService.translate('user_association_confirmation_page-message').replace("@email", this.data.user);
    this.detail = this.translocoService.translate('user_association_confirmation_page-message_detail').replace("@email", this.data.user);
    this.question = this.translocoService.translate('user_association_confirmation_page-message_question').replace("@email", this.data.user);
    this.currentCustomer = "- " + this.data.currentCustomer.name + "(" + this.data.currentCustomer.id + ")";

    for(var customer of this.data.customers)
    {
      this.customers = this.customers + "- " + customer.name + "(" + customer.id + ")<br>";
    }
  }
}
