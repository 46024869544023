<h1 mat-dialog-title *transloco="let t" class="title">{{ t('admin_add_user_association_page-title') }}</h1>
<div class="main-div">
    <mat-card class="filter-card">
        <mat-card-content>
            <mat-form-field appearance="fill" class="formField">
                <label *transloco="let t">{{t('admin_add_user_association_page-user_email_label')}}: </label>
                <input matInput type="text" (change)="SetUserEmail($event)" [formControl]="userEmailControl">
                <mat-error *ngIf="userEmailControl.hasError('email') && !userEmailControl.hasError('required')">
                    <span *transloco="let t">{{ t('admin_add_user_association_page-user_email_error_label') }}</span>
                </mat-error>
            </mat-form-field>
        </mat-card-content>
        <mat-card-content>
            <mat-form-field appearance="fill" class="formField">
                <label *transloco="let t">{{t('admin_add_user_association_page-customer_label')}}: </label>
                <input matInput type="text" (change)="SetCustomerId($event)" [formControl]="customerIdControl">
                <mat-error *ngIf="!customerIdControl.hasError('required')">
                    <span *transloco="let t">{{ t('admin_add_user_association_page-customer_error_label') }}</span>
                </mat-error>
            </mat-form-field>
        </mat-card-content>  
        <mat-card-content class="actionButtons">
            <button mat-raised-button type="button" class="navButton addButton" (click)="SaveUserAssociation()" [disabled]="loading">
                <mat-icon class="toolbar-icon">add</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_add_user_association_page-add_button') }}</span>
            </button>&nbsp;
            <button mat-raised-button color="warn" type="button" class="navButton" [mat-dialog-close]="true" [disabled]="loading">
                <mat-icon class="toolbar-icon">delete</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_add_user_association_page-cancel_button') }}</span>
            </button>            
        </mat-card-content>
    </mat-card>
    <br>
    <div *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>