import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { LoginFlowService } from '../../shared/login-check/login-flow.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  currentUser: User | null = null;

  constructor(private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.checkLogin();
  }

  checkLogin(): void{
    this.loginFlowService.getUser().subscribe({
      next: user=>{
        this.currentUser = user;
        if (!user.admin)
        {
          // Redirect user, is not an admin
          const err = new HttpErrorResponse({ status: 401, error: "Sorry, you don't have access to this feature", statusText: "Sorry, you don't have access to this feature",url: "" });
          this.errorHandler.handleError(err);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
    
  }

}
