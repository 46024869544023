<h1 mat-dialog-title class="title">{{ data.customer.name }} ({{ data.customer.crmId }}) - {{
    data.subscription.description }}</h1>
<div class="main">
    <div class="mat-elevation-z8 table-div">
        <div class="table-div-inner">

            <table mat-table [dataSource]="displayedPage" class="recordsTable">

                <ng-containter matColumnDef="invoiceId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-invoice_id_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.invoiceId }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-invoice_number_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.number }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="subTotal">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-subtotal_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subTotal }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-tax_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.tax }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-total_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.total }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-currency_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.currency }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-status_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.status | uppercase }}
                    </td>
                </ng-containter>
                
                <ng-containter matColumnDef="createdDate">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-created_date_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.createdDate | date:'fullDate' }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="erpOrderNumber">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{
                            t('admin_subscription_invoices_page-erp_order_number_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.erpOrderNumber }}&nbsp;
                        <button mat-raised-button color="primary" type="button" (click)="ReSendOrder(record)" *ngIf="(record.status != 'paid' && record.status != 'void') && record.erpOrderNumber == null">
                            <span *transloco="let t">{{ t('admin_subscription_invoices_page-resend_button_column')
                                }}</span>
                        </button>
                    </td>
                </ng-containter>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"></tr>
            </table>
            <div *ngIf="showLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <mat-paginator #paginator [length]="displayedColumns.length" [length]="records.length" [pageSize]="pageSize"
            [pageIndex]="pageIndex" [disabled]="showLoading" [showFirstLastButtons]="false"
            [pageSizeOptions]="[10, 15, 30, 50, 100]" [hidePageSize]="false">
        </mat-paginator>
    </div>
</div>