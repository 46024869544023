import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Customer } from 'src/app/shared/payment/interfaces/customer.model';
import { CustomerService } from 'src/app/shared/payment/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { LoginFlowService } from '../login-flow.service';
import { SubscriptionCheckout } from '../../payment/interfaces/subscription-checkout.model';
import { CookieService, SameSite } from 'ngx-cookie-service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  loading: boolean = true;
  displayedColumns: string[] = ['customerId', 'customerName', 'subscriptionDescription', 'price', 'url'];
  customer: Customer | null = null;

  checkouts: SubscriptionCheckout[] = [];

  constructor(private customerService: CustomerService,
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private cookieService: CookieService,
    private dialogRef: MatDialog) { }

  ngOnInit(): void {
    this.getPendingCheckouts();
  }

  getPendingCheckouts(): void {
    this.loginFlowService.getPendingCheckouts().subscribe({
      next: checkouts => {
        var stripeCheckouts = checkouts.filter(checkout => checkout.acceptInStripe);
        stripeCheckouts.forEach((checkout) => {
          if (checkout.crmParentId != null && checkout.crmParentId != '' && parseInt(checkout.price.amountDecimal) == 0) {
            this.displayedColumns = ['customerId', 'customerName', 'parent', 'subscriptionDescription', 'url']
          }
          else if (checkout.crmParentId != null && checkout.crmParentId != '') {
            this.displayedColumns = ['customerId', 'customerName', 'parent', 'subscriptionDescription', 'price', 'url']
          }
        });
        if (stripeCheckouts?.length == 1 && stripeCheckouts[0].url) {
          this.continueClick(stripeCheckouts[0].url, undefined);
        } else {
          this.checkouts = stripeCheckouts;
        }
        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  // getCustomer(): void{
  //   this.loginFlowService.getCustomerAssignments().subscribe({
  //     next: customers=> {
  //       this.loading = false;
  //       var customerRequiringSetup = customers.find(customer=> !customer.completedSetup);
  //       if (customerRequiringSetup != null){
  //         this.customer = customerRequiringSetup;
  //       }
  //     },
  //     error: (err: HttpErrorResponse) => {
  //       this.errorHandler.handleError(err);
  //     }
  //   })
  // }

  continueClick(url: string, termsAndConditionsPDFName?: string): void {
    if (termsAndConditionsPDFName != null) {
      var sameSite: SameSite = 'Strict';
      this.cookieService.set('GSMSelectedSubscription', termsAndConditionsPDFName, 1, undefined, undefined, undefined, sameSite);
    }
    // window.location.href = url;
    window.open(url, "_blank");
    this.dialogRef.closeAll();

  }

  refreshClick(): void {
    this.loading = true;
    this.loginFlowService.refreshSubscriptionCheckouts();
  }

  public getCurrencySymbols(checkout: SubscriptionCheckout): string {
    var symbol;
    symbol = getCurrencySymbol(checkout.price.currency, 'narrow', undefined);
    return symbol;
  }
}
