import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, retry, throwError } from 'rxjs';

import { Product } from '../interfaces/product.model';
import { ProductCategory } from "../interfaces/product-category.model";

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Price } from '../interfaces/price.model';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  private _baseUrl = environment.apiBaseURL + "Payment/Price";

  constructor(private http: HttpClient) { 
  }

  getPrices(product: Product):Observable<Price[]>{
    return this.http.post<Price[]>(this._baseUrl+"/ProductList", product, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    )
  }

  getPricesByProductId(productId: string):Observable<Price[]>{
    return this.http.post<Price[]>(this._baseUrl+"/ProductListById?productId=" + productId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    )
  }  
}
