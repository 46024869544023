<h1 mat-dialog-title *transloco="let t" class="title">{{ t('add_subscription_phase-title') }}</h1>
<div class="main-div">
    <mat-card class="filter-card">
        <mat-card-content>
            <mat-form-field appearance="fill" class="formField">
                <mat-label *transloco="let t">{{t('add_subscription_phase-price')}}: </mat-label>
                <mat-select [formControl]="selectedPhaseControl">
                    <mat-option matInput *ngFor="let item of data.phases" [value]="item.id" (click)="SetPhase(item)">
                        {{ item.description }} - {{ item.amountDecimal }}&nbsp;{{ item.currency }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>         
        <mat-card-content>
            <label *transloco="let t">{{t('add_subscription_phase-start_date')}}: </label>
            <span>{{ data.startDate | date:'fullDate' }} </span>
        </mat-card-content>
        <mat-card-content>
            <mat-form-field>
                <input *transloco="let t" matInput [matDatepicker]="picker" [min]="data.startDate"
                        [formControl]="endDateControl"
                        placeholder="{{ t('update_subscription_schedule_page-choose_end_date_label') }}">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker ></mat-datepicker>
            </mat-form-field>    
        </mat-card-content>
        <mat-card-content class="actionButtons">
            <button mat-raised-button type="button" color="primary" class="actionButtons" (click)="AddPhase()" [disabled]="loading">
                <mat-icon class="toolbar-icon">add</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_add_user_association_page-add_button') }}</span>
            </button>&nbsp;
            <button mat-raised-button color="warn" type="button" class="actionButtons" [mat-dialog-close]="false" [disabled]="loading">
                <span  *transloco="let t">{{ t('admin_add_user_association_page-cancel_button') }}</span>
            </button>            
        </mat-card-content>        
    </mat-card>
    <br>
    <div *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>