<div class="main-div">
    <mat-card class="filter-card">
        <mat-card-content>
            <label *transloco="let t">{{t('invoices_resubmit_page-confirmation_message')}}: </label>
            <br>
            <div *ngIf="data.erpOrderNumber != ''" class="newOrderNumber">
                <label *transloco="let t">{{t('invoices_resubmit_page-new_order_number')}}{{ data.erpOrderNumber }}</label>
            </div>
            <br>
        </mat-card-content>        
        <mat-card-content class="actionButtons">
            <button mat-raised-button color="primary" type="button" class="navButton" (click)="ResubmitOrder()" [disabled]="loading">
                <span  *transloco="let t">{{ t('invoices_resubmit_page-yes_button') }}</span>
            </button>&nbsp;
            <button mat-raised-button color="default" type="button" class="navButton" [mat-dialog-close]="true" [disabled]="loading">
                <span  *transloco="let t">{{ t('invoices_resubmit_page-cancel_button') }}</span>
            </button>            
        </mat-card-content>
    </mat-card>
    <br>
    <div *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>