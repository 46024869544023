import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { SubscriptionDashboardRecord } from 'src/app/pages/subscriptions-dashboard-page/interfaces/subscription-dashboard-record.module'
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { Subject, takeUntil } from 'rxjs';
import { AdminSubscriptionInvoicesComponent } from '../admin-subscription-invoices/admin-subscription-invoices.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-past-due',
  templateUrl: './admin-past-due.component.html',
  styleUrls: ['./admin-past-due.component.scss']
})
export class AdminPastDueComponent implements OnInit {
  displayedColumns: string[] = ['crmId', 'name', 'country', 'status', 'erpBillingId', "crmParentId", "parentName", 'description', 'submittedBy', 'created', 'id'];
  records: SubscriptionDashboardRecord[] = [];
  displayedRecords: SubscriptionDashboardRecord[] = [];
  displayedPage: SubscriptionDashboardRecord[] = [];
  pageSize: number = 10;
  pageIndex: number = 0;
  reachedEnd: boolean = false;
  showLoading: boolean = false;
  showSearchLoading = false;
  filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  filterValue: string = "";
  private readonly _destroying$ = new Subject<void>();
  
  constructor(private subscriptionService: SubscriptionService,
    private errorHandler: ErrorHandlerService,
    private detailsDialog: MatDialog,) {
    
   }

  ngOnInit(): void {
    this.getPastDueSubscriptions();
    this.getFilter();
  }

  getPastDueSubscriptions(){
    this.showLoading = true;
    this.records = [];
    this.displayedPage = [];
    this.subscriptionService.getPastDueSubscriptions().pipe(takeUntil(this._destroying$)).subscribe({
      next: customers=>{
        customers.forEach(customer=>{
          customer.subscriptions.forEach(subscription=> {
              this.records.push({ customer: customer, subscription: subscription });
            });
          })
        this.displayedRecords = this._customerFilter(this.filterValue).slice();
        this.getCurrentPage();
        this.showLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.showLoading = false;
        this.errorHandler.handleError(err);
      }
    });   
  }

  handlePageEvent(e: any) {
    if (this.pageSize != e.pageSize) {
      this.pageIndex = 0;
      this.pageSize = e.pageSize;
    } else {
      this.pageIndex = e.pageIndex;
    }
    this.getCurrentPage();
  }

  getCurrentPage(): void {
    var startIndex = this.pageIndex * this.pageSize;
    var endIndex = this.pageIndex * this.pageSize + this.pageSize;
    this.displayedPage = this.records.slice(startIndex, endIndex > this.records.length ? this.records.length : endIndex);
  }

  private _customerFilter(value: string): SubscriptionDashboardRecord[] {
    const filterValue = value.toLowerCase();
    if(filterValue === "4ll_v4lue5"){
      return this.records;
    }
    return this.records.filter(record => record?.subscription.crmCustomerId.toLowerCase().includes(filterValue)
      || record?.subscription?.customerName?.toLowerCase()?.includes(filterValue)
      || record?.customer?.country?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.erpBillingId?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.status?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.description?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.contact?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.submittedBy?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.created?.toString()?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.id?.toLowerCase()?.includes(filterValue)
    )
  }

  applyFilter(event: Event) {
    this.showSearchLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValue$.next(filterValue.trim().toLowerCase());
  }

  resetRecords(){
    this.records=[].slice();
    this.displayedRecords=[].slice();
    this.displayedPage=[].slice();
    this.showLoading=false;
    this.showSearchLoading = false;
    this.filterValue$.next("4ll_v4lue5");
  }

  getFilter(): void {
    this.filterValue$.pipe(
      debounceTime(3000),
      distinctUntilChanged(),
      filter(value=> value.length >=2),
    ).subscribe(filterValue=>{
      this.showSearchLoading = false;
      this.filterValue = filterValue;
      this.records = this._customerFilter(filterValue).slice();
      this.getCurrentPage();
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  openSubscriptionInvoicesDialog(row: SubscriptionDashboardRecord): void{
    const dialogRef = this.detailsDialog.open(AdminSubscriptionInvoicesComponent,
      {
        width: '110vw',
        height: '40vw',       
        data: row,
        autoFocus: false
       });
  }
}
