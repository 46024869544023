import { Component,Inject,OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';

@Component({
  selector: 'app-subscriptions-dashboard-cs-email.component',
  templateUrl: './subscriptions-dashboard-cs-email.component.html',
  styleUrls: ['./subscriptions-dashboard-cs-email.component.scss']
})
export class SubscriptionsDashboardCsEmailComponent implements OnInit {
  loading: boolean = false;

  constructor(public dialogRef: MatDialogRef<SubscriptionsDashboardCsEmailComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public data: string,
    private _snackBar: MatSnackBar,        
    private translocoService: TranslocoService,
    private errorHandler: ErrorHandlerService,
    private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
  }

  SendCSEmail(){
    this.loading = true;
    this.subscriptionService.resendCSEmail(this.data)
      .subscribe({
       next: result => {
          if (result)
          {
            this._snackBar.open(this.translocoService.translate('subscription_dashboard_cs_email_page-email_success'), this.translocoService.translate('general_message_dismiss'));
            this.dialogRef.close();
          }
          else
          {
            this._snackBar.open(this.translocoService.translate('subscription_dashboard_cs_email_page-email_error'), this.translocoService.translate('general_message_dismiss'));
          }
          this.loading = false;
       },
       error: (err: HttpErrorResponse) => {
         this.errorHandler.handleError(err);
         this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'));
         this.loading = false;
       }
     }); 
  }
}
