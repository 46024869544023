import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { CustomerService } from 'src/app/shared/payment/services/customer.service';
import { LoginFlowService } from '../login-flow.service';
import { Customer } from 'src/app/shared/payment/interfaces/customer.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaxId } from 'src/app/shared/payment/interfaces/tax-id.model';
import { interval, takeWhile } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Vat } from '../../payment/vat.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-tax-validation',
  templateUrl: './tax-validation.component.html',
  styleUrls: ['./tax-validation.component.scss']
})
export class TaxValidationComponent implements OnInit {
  user!: User;
  customer!: Customer
  unexpectedErrorMessage = false;
  countryControl = new FormControl<string>('');
  taxIdTypeControl = new FormControl<string>('', [Validators.required]);
  taxIdControl = new FormControl<string>('', [Validators.required]);
  unVerifiedErrorMessage = false;
  submitted = false;
  progressDots = 0;
  loading = true;

  newTaxId!: TaxId;
  taxIdLabel: string = "";

  taxIdForm = new FormGroup({
    countryControl: this.countryControl,
    taxIdTypeControl: this.taxIdTypeControl,
    taxIdControl: this.taxIdControl
  })

  taxTypes?: Vat[] = [];
  waiting = false;

  constructor(private customerService: CustomerService,
    private loginFlowService: LoginFlowService,
    private errorHandler: ErrorHandlerService,
    private translocoService: TranslocoService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getUser();
    this.setTaxId();
    this.checkUserInput();
  }

  getUser(): void {
    this.loginFlowService.getUser().subscribe(user => {
      this.user = user;
      this.getCustomer();
    });
  }

  checkUserInput(): void {
    this.loginFlowService.isUserInputRequired().subscribe(inputRequired => this.waiting = !inputRequired);
  }


  getCustomer(): void {
    this.loginFlowService.getCustomerAssignments()
      .subscribe({
        next: customers => {
          var result = customers.find(customer => customer.taxIdStatus != 'initialized');
          if (result != undefined) {
            this.customer = result;
            if (this.customer?.vats && this.customer?.vats?.length > 0) {
              this.taxTypes = this.customer.vats;
              this.taxIdTypeControl.setValue(this.taxTypes[0].tax_Enum);
              this.taxIdControl.setValidators([Validators.required, Validators.pattern(this.taxTypes[0].tax_Format)]);
              this.taxIdLabel = this.translocoService.translate('home_page-tax_validation-tax_id') + 
              ' (' + this.translocoService.translate('example') + ': ' + this.taxTypes[0].example + ')';
            }
            this.waiting = false;
            this.loading = false;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
  }

  setTaxId(): void {
    this.taxIdForm.valueChanges.subscribe(() => this.newTaxId = {
      id: "",
      taxType: this.taxIdTypeControl.value ?? "",
      value: this.taxIdControl.value ?? "",
      status: "",
      customerId: this.customer.id
    })
  }

  submitClick(): void {
    this.waiting = true;
    this.unVerifiedErrorMessage = false;
    this.unexpectedErrorMessage = false;
    if (this.submitted) {
      this.verifyTaxId();
    } else {
      this.customerService.setTax(this.newTaxId).subscribe({
        next: result => {
          this.newTaxId.id = result.id;
          this.newTaxId.status = result.status;
          this.submitted = true;
          this.verifyTaxId();
        },
        error: (err: HttpErrorResponse) => {
          this.unexpectedErrorMessage = true;
          this.waiting = false;
        }
      });
    }
  }

  verifyTaxId(): void {
    var pendingInterval = interval(5000);
    this.progressDots = 10;
    var limit = 0;
    this.customer.taxIdStatus = "pending";
    pendingInterval.pipe(takeWhile(() => (this.customer.taxIdStatus != "initialized" && this.customer.taxIdStatus != "failed")
      && !this.unexpectedErrorMessage)).subscribe(() => {
        limit += 1;
        this.progressDots = this.progressDots + Math.ceil((100 - this.progressDots) / 12);
        if (limit > 24)
        {
          this.completeVerifyTaxId("unavailable");//end wait
        }
        this.customerService.validateTax(this.newTaxId).subscribe({
          next: result => {
            this.completeVerifyTaxId(result.status);
          },
          error: (err: HttpErrorResponse) => {
            this.unexpectedErrorMessage = true;
            this.waiting = false;
          }
        })
      })
  }

  completeVerifyTaxId(status: string): void {
    if (status == 'verified' || status == 'unavailable') {
      this.progressDots = 100;
      this.customer.taxIdStatus = 'initialized';
      this.waiting = false;
      this._snackBar.open(this.translocoService.translate('tax_validation-success'), this.translocoService.translate('general_message_dismiss'));
      this.loginFlowService.setCustomer(this.customer);
      this.loginFlowService.calculateState();
    } else if (status == "unverified") {
      this.customer.taxIdStatus = 'failed';

      this.customerService.removeTax(this.newTaxId).subscribe({
        next: result => {
          this.progressDots = 100;
          this.waiting = false;
          this._snackBar.open(this.translocoService.translate('home_page-tax_validation-verified_error'), this.translocoService.translate('general_message_dismiss'));
          this.setTaxId();
          this.submitted = false;
        },
        error: (err: HttpErrorResponse) => {
          this.unexpectedErrorMessage = true;
          this.waiting = false;
        }
      })
    }
  }

  setTax(event: MatSelectChange) {
    let vat = this.taxTypes?.find(x => x.tax_Enum == event.value);
    if (vat) {
      this.taxIdControl.setValidators([Validators.required, Validators.pattern(vat.tax_Format)]);
      this.taxIdLabel = this.translocoService.translate('home_page-tax_validation-tax_id') +
        ' (' + this.translocoService.translate('example') + ': ' + vat?.example + ')';
    }
  }
}
