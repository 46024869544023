<ng-container *transloco="let t">
    <div class="main-div">
        <div class="information-details">
            <div class="information-segment">
                <label>{{t('update_subscription_schedule_page-company_name')}}: </label>
                <span>{{ data.subscriptionData.customer.name }} ({{ data.subscriptionData.customer.crmId }})</span>
            </div>
            <br />
            <div class="information-segment">
                <label>{{t('update_subscription_schedule_page-subscription_id')}}: </label>
                <span>{{ data.subscriptionData.subscription.id }}</span>
            </div>
            <br />
            <div class="information-segment">
                <label>{{t('update_subscription_schedule_page-subscription_description')}}: </label>
                <span>{{ data.subscriptionData.subscription.description }}</span>
            </div>
            <br />
            <div class="information-segment">
                <label>{{t('update_subscription_schedule_page-contact')}}: </label>
                <span>{{ data.subscriptionData.subscription.contact }}</span>
            </div>
            <br />
            <div class="information-segment">
                <label>{{t('update_subscription_schedule_page-submitted_by')}}: </label>
                <span>{{ data.subscriptionData.subscription.submittedBy }}</span>
            </div>
            <br />
            <div class="information-segment">
                <label>{{t('update_subscription_schedule_page-created_date')}}: </label>
                <span>{{ data.subscriptionData.subscription.created }}</span>
            </div>
            <br />

            <div class="information-segment-no-schedules" *ngIf="!hasSchedule">
                <label>{{t('update_subscription_schedule_page-no_schedules_message')}}</label>
            </div>
            <br />
            <br />

            <div class="information-segment information-segment-title">
                <label>{{t('update_subscription_schedule_page-details_title')}}</label>
            </div>
            <br />

            <div *ngIf="showLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="information-segment" *ngIf="showSchedules">
                <mat-card class="filter-card">
                    <mat-card-content>

                        <table mat-table [dataSource]="dataSource" class="recordsTable">

                            <ng-containter matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef><span>{{
                                        t('update_subscription_schedule_page-price_name_column') }}</span></th>
                                <td mat-cell *matCellDef="let record" class="recordsCell">
                                    <mat-form-field appearance="fill" class="priceDropdown">
                                        <mat-select [(value)]="record.id">
                                            <mat-option [id]="item.id" matInput *ngFor="let item of phasesDropDown"
                                                [value]="item.id">
                                                {{ item.description }} - {{ item.amountDecimal }}&nbsp;{{ item.currency
                                                }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-containter>
                            <ng-containter matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef><span>{{
                                        t('update_subscription_schedule_page-start_date_column') }}</span></th>
                                <td mat-cell *matCellDef="let record" class="recordsCell">
                                    {{ record.schedule.startDate | date:'fullDate' }}
                                </td>
                            </ng-containter>

                            <ng-containter matColumnDef="trial">
                                <th mat-header-cell *matHeaderCellDef><span>{{
                                        t('update_subscription_schedule_page-trial_column') }}</span></th>
                                <td mat-cell *matCellDef="let record; index as i" class="recordsCell mat-cell-trial">
                                    <mat-checkbox #checkbox [(ngModel)]="trial" (change)="SetTrial(checkbox.checked)"
                                        *ngIf="dataSource.length > 1 && i == 0"> </mat-checkbox>
                                </td>
                            </ng-containter>

                            <ng-containter matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef><span>{{
                                        t('update_subscription_schedule_page-end_date_column') }}</span></th>
                                <td mat-cell *matCellDef="let record; let rowIndex = index" class="recordsCell">
                                    <mat-form-field>
                                        <input id="{{ record.id }}" matInput [matDatepicker]="picker"
                                            [min]="record.schedule.startDate" (dateChange)="SetDate($event, rowIndex)"
                                            [value]="record.schedule.endDate"
                                            placeholder="{{ t('update_subscription_schedule_page-choose_end_date_label') }}">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </ng-containter>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="recordRow"></tr>
                        </table>
                    </mat-card-content>
                </mat-card>
                <mat-card class="filter-card">
                    <div class="information-segment-center">
                        <button mat-raised-button color="primary" type="button" class="navButton" (click)="AddPhase()"
                            [disabled]="showLoading">
                            <span>{{ t('update_subscription_schedule_page-add_phase_button')
                                }}</span>
                            &nbsp;<mat-icon class="toolbar-icon">add</mat-icon>
                        </button>
                    </div>
                </mat-card>
            </div>
            <br />
            <div class="information-segment-center">
                <button mat-raised-button color="primary" type="button" class="navButton" (click)="Save()"
                    [disabled]="showLoading">
                    <span>{{ t('update_subscription_schedule_page-save_button') }}</span>
                </button>
                <button mat-raised-button color="default" type="button" class="navButton" mat-dialog-close
                    [disabled]="showLoading">
                    <span>{{ t('update_subscription_schedule_page-cancel_button') }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-container>