import { Component,Inject,OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { UserService } from 'src/app/shared/user/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';

@Component({
  selector: 'app-admin-add-user-association',
  templateUrl: './admin-add-user-association.component.html',
  styleUrls: ['./admin-add-user-association.component.scss']
})
export class AdminAddUserAssociationComponent implements OnInit {
  loading: boolean = false;
  userEmailControl = new FormControl<string>('', [Validators.required,Validators.email]);
  customerIdControl = new FormControl<string>('', [Validators.required]);
  
  constructor(public dialogRef: MatDialogRef<AdminAddUserAssociationComponent>,     
    @Inject(MAT_DIALOG_DATA)
    public data: string,
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private errorHandler: ErrorHandlerService,
    private userService: UserService) { }

  ngOnInit(): void {
  }

  SetUserEmail(event: Event): void{
    const newValue = (event.target as HTMLInputElement).value;
    this.userEmailControl.setValue(newValue);
  }

  SetCustomerId(event: Event): void{
    const newValue = (event.target as HTMLInputElement).value;
    this.customerIdControl.setValue(newValue);
  }

  SaveUserAssociation(){
    if (this.userEmailControl.value == "" && this.customerIdControl.value == "")
    {
      this._snackBar.open(this.translocoService.translate('admin_add_user_association_page-save_error_label'), this.translocoService.translate('general_message_dismiss'));
    }
    else
    {
      this.loading = true;
      this.userService.addCustomerId(this.userEmailControl.value ?? "", this.customerIdControl.value ?? "")
      .subscribe({
        next: result => {
          if (result == null)
          {
            this._snackBar.open(this.translocoService.translate('admin_add_user_association_page-save_success'), this.translocoService.translate('general_message_dismiss'));
            this.dialogRef.close();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.handleError(err);
          this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'));
        }
      });
    }
  }
}
