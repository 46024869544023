<app-toolbar [pageName]="t('home_page-title')" *transloco="let t"></app-toolbar>
<div  *ngIf="currentUser != null && validLogin; else loadingDiv">
  <div class="main-div">
    <mat-card class="home-card basic-card mat-elevation-z8" routerLink="/new-subscription" *ngIf="!currentUser.external">
      <mat-card-header class="home-card-header"></mat-card-header>
      <mat-card-content class="home-card-content background accent">
          <mat-icon class="card-icon" color="primary">shopping_cart</mat-icon>
      </mat-card-content>
      <mat-card-footer class="home-card-footer">
        <mat-card-title  *transloco="let t">{{ t('home_page-add_new_subscription') }}</mat-card-title>
      </mat-card-footer>
    </mat-card>
  
    <!-- <mat-card class="home-card basic-card mat-elevation-z8" routerLink="/incomplete-subscriptions">
      <mat-card-header class="home-card-header"></mat-card-header>
      <mat-card-content class="home-card-content"  matBadge="{{incompleteSubs.length}}" matBadgeColor="warn" matBadgeSize="large" matBadgeHidden="{{incompleteSubs.length === 0}}">
        <mat-icon class="card-icon" color="primary" >info_outline</mat-icon>
      </mat-card-content>
      <mat-card-footer class="home-card-footer">
        <mat-card-title  *transloco="let t">{{ t('home-incomplete_subscription') }}</mat-card-title>
      </mat-card-footer>
    </mat-card> -->
  
    <mat-card class="home-card basic-card mat-elevation-z8" routerLink="/payments" *ngIf="currentUser.external">
      <mat-card-header class="home-card-header"></mat-card-header>
      <mat-card-content class="home-card-content">
        <mat-icon class="card-icon" color="primary">payment</mat-icon>
      </mat-card-content>
      <mat-card-footer class="home-card-footer">
        <mat-card-title  *transloco="let t">{{ t('home_page-upcoming_payments') }}</mat-card-title>
      </mat-card-footer>
    </mat-card>
  
    <mat-card class="home-card basic-card mat-elevation-z8" routerLink="/subscription/dashboard" *ngIf="!currentUser.external">
      <mat-card-header class="home-card-header"></mat-card-header>
      <mat-card-content class="home-card-content">
        <mat-icon class="card-icon" color="primary">dashboard</mat-icon>
      </mat-card-content>
      <mat-card-footer class="home-card-footer">
        <mat-card-title  *transloco="let t">{{ t('home_page-dashboard') }}</mat-card-title>
      </mat-card-footer>
    </mat-card>

    <mat-card class="home-card basic-card mat-elevation-z8" (click)="navigateToAccount()">
      <mat-card-header class="home-card-header"></mat-card-header>
      <mat-card-content class="home-card-content">
        <mat-icon class="card-icon" color="primary">person</mat-icon>
      </mat-card-content>
      <mat-card-footer class="home-card-footer">
        <mat-card-title  *transloco="let t">{{ t('home_page-manage_accounts') }}</mat-card-title>
      </mat-card-footer>
    </mat-card>

    <mat-card class="home-card basic-card mat-elevation-z8" routerLink="/admin" *ngIf="currentUser.admin">
      <mat-card-header class="home-card-header"></mat-card-header>
      <mat-card-content class="home-card-content">
        <mat-icon class="card-icon" color="primary">settings</mat-icon>
      </mat-card-content>
      <mat-card-footer class="home-card-footer">
        <mat-card-title  *transloco="let t">{{ t('home_page-admin') }}</mat-card-title>
      </mat-card-footer>
    </mat-card>    
  
    <!-- <mat-card class="home-card basic-card mat-elevation-z8">
      <mat-card-header class="home-card-header"></mat-card-header>
      <mat-card-content class="home-card-content">
        <mat-icon class="card-icon" color="primary">people</mat-icon>
      </mat-card-content>
      <mat-card-footer class="home-card-footer">
        <mat-card-title  *transloco="let t">{{ t('home-user_administration') }}</mat-card-title>
      </mat-card-footer>
    </mat-card> -->
  </div>
</div>
<ng-template #loadingDiv>
  <div class="main-div">
    <div *ngIf="spinnerWhileLoading">
      <mat-spinner class="home-card" color="accent"></mat-spinner>
    </div>
  </div>
</ng-template>


