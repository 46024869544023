import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { SubscriptionDashboardRecord } from 'src/app/pages/subscriptions-dashboard-page/interfaces/subscription-dashboard-record.module';
import { RequiredInfoOption } from 'src/app/shared/subscription/interfaces/required_info_option.model';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { UpdateSubscriptionDetails } from 'src/app/pages/subscriptions-dashboard-page/interfaces/update-subscription-details.model'
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';
import { ContactSelectorComponent } from 'src/app/pages/new-subscription-page/contact-selector/contact-selector.component';
import { ContactService } from 'src/app/shared/customer/services/contact.service';
import { Customer } from 'src/app/shared/customer/interfaces/customer.model';
import { Contact } from 'src/app/shared/customer/interfaces/contact.model';
import { LoginFlowService } from 'src/app/shared/login-check/login-flow.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged, EMPTY, filter, map, merge, mergeAll, Observable, of, startWith, switchMap } from 'rxjs';
import { Price } from 'src/app/shared/subscription/interfaces/price.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment.local';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { ProductService } from 'src/app/shared/subscription/services/product.service';
import { ProductCategory } from 'src/app/shared/subscription/interfaces/product-category.model';
import { Product } from 'src/app/shared/subscription/interfaces/product.model';
import { SubscriptionsCancelPageComponent } from '../../subscriptions-dashboard-cancel-subscription.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { jsPDF } from 'jspdf';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Html2CanvasOptions } from 'jspdf';
import html2canvas from 'html2canvas';
import { SubscriptionsDashboardCsEmailComponent } from '../../subscriptions-dashboard-cs-email/subscriptions-dashboard-cs-email.component';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { ConfirmErpOrderResubmitPageComponent } from '../confirm-erp-order-resubmit-page/confirm-erp-order-resubmit-page.component';

@Component({
    selector: 'app-subscriptions-dashboard-details-page',
    templateUrl: './subscriptions-dashboard-details-page.component.html',
    styleUrls: ['./subscriptions-dashboard-details-page.component.scss']
})
export class SubscriptionsDashboardDetailsPageComponent implements OnInit, AfterViewInit
{
    paidBy: number = 0;
    allowEditSubscriptionDetails: boolean = false;
    showAdditionalDetailsSection: boolean = false;
    allowEditDetailsSection: boolean = true;
    showActionButtons: boolean = false;
    contactEmailControl = new FormControl<string>('', [Validators.required, Validators.email]);
    distributorEmailControl = new FormControl<string>('', [Validators.required, Validators.email]);
    distributionControl = new FormControl<string>('', Validators.required);
    regionControl = new FormControl<string>('', Validators.required);
    siteControl = new FormControl<string>('', Validators.required);
    distributions: RequiredInfoOption[] = [];
    regions: RequiredInfoOption[] = []!;
    sites: RequiredInfoOption[] = [];
    selectedDistribution!: RequiredInfoOption;
    selectedRegion!: RequiredInfoOption;
    selectedSite!: RequiredInfoOption;
    regionOptions: RequiredInfoOption[] = [];
    sitesOptions: RequiredInfoOption[] = [];
    selectedCustomer!: Customer;
    customerContacts: Contact[] = [];
    contacts: Contact[] = [];
    selectedCustomerContact!: Contact;
    selectedDistributorContact!: Contact;
    distributorEmail: string = "";
    showLoading: boolean = true;
    sendingCustEmail: boolean = false;
    sendingThirdEmail: boolean = false;
    oldCustmerContact!: Contact;
    oldDistribuorContact!: Contact;
    savingData: boolean = false;
    showSchedules: boolean = false;
    schedule: Price[] = [];
    displayedColumns: string[] = ['description'];
    isLoadingSchedule: boolean = false;
    allowUpdateScheduleDate: boolean = false;
    isAdmin: boolean = false;
    tcLink: string = "";
    activeLang: string = "en";
    tcVersion: string = "v1";
    isLoadingTC: boolean = false;
    tcFileFound: boolean = false;
    distributor!: Customer;
    productCategories: ProductCategory[] = [];
    productControl = new FormControl<string | Product>({ value: '', disabled: true }, Validators.required);
    product!: Product;
    loadingProducts: boolean = false;
    selectedProduct: string = "";
    currentUser!: User;
    distributorInvolvedControl = new FormControl<string>('');
    distributorInvolved: string = "";
    erpOrderNumberControl = new FormControl<string>('');
    erpBillingIdControl = new FormControl<string>('');
    submittedByControl = new FormControl<string>('', [Validators.required,Validators.email]);
    showResubmitOracleOrder: boolean = false;
    limitedPartNumbersToBeSentToOracle: boolean = false;
    @ViewChild('test') content!: ElementRef;

    editSubscriptionsFormGroup = new FormGroup({
        contactEmailControl: this.contactEmailControl,
        distributorEmailControl: this.distributorEmailControl,
        distributionControl: this.distributionControl,
        regionControl: this.regionControl,
        siteControl: this.siteControl
    })

    constructor(public dialogRef: MatDialogRef<SubscriptionsDashboardDetailsPageComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: SubscriptionDashboardRecord,
        private subscriptionService: SubscriptionService,
        private errorHandler: ErrorHandlerService,
        private _snackBar: MatSnackBar,
        private translocoService: TranslocoService,
        private contactDialog: MatDialog,
        private loginFlowService: LoginFlowService,
        private contactService: ContactService,
        private customerService: CustomerService,
        private productService: ProductService,
        private cancelSubscriptionDialog: MatDialog,
        private resubmitERPOrderDialog: MatDialog,
        private fb: FormBuilder) { }

    ngOnInit(): void
    {
        this.activeLang = this.translocoService.getActiveLang();
        this.checkLogin();
        this.getProductDetails();
        this.paidBy = this.data.subscription.paidBy;
        this.selectedCustomerContact = this.data.subscription.customerContact.filter(x => x.email == this.data.subscription.contact)![0];
        this.oldCustmerContact = this.selectedCustomerContact;
        this.displayedColumns = ['description', 'subtotal', 'startDate', 'endDate'];
        if ((this.data.subscription.status.toUpperCase() == "ACTIVE" || this.data.subscription.status.toUpperCase() == "UNPAID" || this.data.subscription.status.toUpperCase() == "PAST_DUE") && this.data.subscription.scheduleId != null)
        {
            // Get schedule data
            this.getSubscriptionSchedule();
        }
        if (this.paidBy == 2)
        {
            this.selectedDistributorContact = this.data.subscription.payerContact[0];
            this.oldDistribuorContact = this.selectedDistributorContact;
        }
        if (this.data.subscription.regionId != "" && this.data.subscription.siteId != "" && this.data.subscription.distributionId != "")
        {
            this.showAdditionalDetailsSection = true;
            this.GetRequiredInfo();
        }

        if (this.data.subscription.crmParentId != null)
        {
            this.distributorEmail = this.data.subscription.payerContact.find(pc => pc.email.toLowerCase() === this.data.subscription.parentEmail.toLowerCase())?.email ?? ""
        }
        this.showLoading = false;

        if ((this.data.subscription.status.toUpperCase() == "ACTIVE" || this.data.subscription.status.toUpperCase() == "UNPAID" || this.data.subscription.status.toUpperCase() == "PAST_DUE"))
        {
            this.getTCFileLink(this.data.subscription.tcFile, this.data.subscription.id);
        }

        if (this.data.subscription.distSale == "Y")
        {
            this.getDistributor(this.data.customer.crmId);
        }
        this.distributorInvolvedControl.setValue(this.data.subscription.distSale ?? "N");
        this.distributorInvolved = this.data.subscription.distSale ?? "N";
        this.data.subscription.crmParentId;
    }

    getProductDetails(){        
        !this.data.subscription.hardwareShipped == null;
        this.productService.getProduct(this.data.subscription.productId).subscribe({
            next: details =>
            {
                this.limitedPartNumbersToBeSentToOracle = details.limitedPartNumbersToBeSentToOracle;
                this.ResubmitOrderAvailable();
            },
            error: (err: HttpErrorResponse) =>
            {
                this.errorHandler.handleError(err);
                this.isLoadingSchedule = false;
            }
        });
    }

    ResubmitOrderAvailable(){
        var hardwareShipped: Boolean = this.data.subscription.hardwareShipped == null ? false : this.data.subscription.hardwareShipped;
        this.showResubmitOracleOrder = false;
        if (!this.limitedPartNumbersToBeSentToOracle && this.isAdmin && 
            this.data.subscription.erpOrderNumber == null && !hardwareShipped)
            {
                this.showResubmitOracleOrder = true;
            }
    }

    getSubscriptionSchedule()
    {
        if (this.data.subscription.scheduleId)
        {
            this.isLoadingSchedule = true;
            this.subscriptionService.getSubscriptionSchedule(this.data.subscription.scheduleId).subscribe({
                next: details =>
                {
                    this.schedule = details;
                    if (details.length > 0)
                    {
                        if (details.filter(x => x.description.toUpperCase() == "LEAD TIME").length > 0)
                        {
                            this.allowUpdateScheduleDate = details[0].allowUpdateScheduleDate;
                        }
                    }
                    this.showSchedules = (details != null ?? false);
                    this.isLoadingSchedule = false;
                },
                error: (err: HttpErrorResponse) =>
                {
                    this.errorHandler.handleError(err);
                    this.isLoadingSchedule = false;
                }
            });
        }
    }

    checkLogin(): void
    {
        this.loginFlowService.getUser().subscribe({
            next: user =>
            {
                this.currentUser = user;
                if (!user.external)
                {
                    this.allowEditSubscriptionDetails = true;
                }
                if (user.authorizations.filter(x => x.customerId == "ALL_CUSTOMERS").length > 0)
                {
                    this.isAdmin = true;
                }
            },
            error: (err: HttpErrorResponse) =>
            {
                this.errorHandler.handleError(err);
            }
        });
    }

    SetupButtons(flag: boolean)
    {
        this.showActionButtons = flag;
        this.allowEditDetailsSection = !flag;
        if (flag == false)
        {
            this.selectedCustomerContact = this.oldCustmerContact;
            this.selectedDistributorContact = this.oldDistribuorContact;
            this.getSubscriptionSchedule();
        }
        if (this.data.subscription.unchargedSubscription)
        {
            this.loadingProducts = true;
            this.customerService.getCustomer(this.data.customer.crmId).subscribe({
                next: customer =>
                {
                    this.productService.getProducts(customer, this.data.subscription.unchargedSubscription).subscribe({
                        next: productGroups =>
                        {
                            if (productGroups.length > 0)
                            {
                                this._resetProductControl(productGroups);
                                this.SetProduct(this.data.subscription.productId);
                                this.loadingProducts = false;
                            }
                        },
                        error: (err: HttpErrorResponse) =>
                        {
                            this.errorHandler.handleError(err);
                            this.loadingProducts = false;
                        }
                    });
                },
                error: (err: HttpErrorResponse) =>
                {
                    this.errorHandler.handleError(err);
                    this.loadingProducts = false;
                }
            });
        }
    }

    private _resetProductControl(categories: ProductCategory[]): void
    {
        if (categories.length > 0)
        {
            this.productCategories = categories.filter(category => category.products.length > 0);
            this.productControl.enable();
        } else
        {
            this.productControl.reset();
            this.productControl.disable();
        }
    }

    toggleProductCategory(index: number)
    {
        this.productCategories[index].expanded = !this.productCategories[index].expanded;
    }

    SetProduct(id: string)
    {
        this.selectedProduct = id;
    }

    GetRequiredInfo()
    {
        this.data.subscription.requiredInfo.forEach(required =>
        {
            switch (required.key)
            {
                case "AI_Distribution":
                    if (this.data.subscription.distributionId != "")
                    {
                        this.distributions = required.options.slice();
                        this.selectedDistribution = this.distributions.filter(x => x.key == this.data.subscription.distributionId)![0];
                        this.distributionControl.setValue(this.selectedDistribution.key);
                    }
                    break;

                case "AI_Region":
                    if (this.data.subscription.regionId != "")
                    {
                        this.regions = required.options.slice();
                        this.regionOptions = required.options.slice();
                        this.selectedRegion = this.regions.filter(x => x.key == this.data.subscription.regionId)![0];
                        this.regionControl.setValue(this.selectedRegion.key);
                    }
                    break;

                case "AI_Site":
                    if (this.data.subscription.siteId != "")
                    {
                        this.sites = required.options.slice();
                        this.sitesOptions = required.options.slice();
                        this.selectedSite = this.sites.filter(x => x.key == this.data.subscription.siteId)![0];
                        this.siteControl.setValue(this.selectedSite.key);
                    }
                    break;
            }
        });
    }

    GetCustomerContacts()
    {
        this.contactService.getContacts(this.data.customer.id).subscribe({
            next: contacts =>
            {
                this.selectedCustomer.contacts = contacts;
                this._resetCustomerContacts(contacts);
            },
            error: (err: HttpErrorResponse) =>
            {
                this.errorHandler.handleError(err);
            }
        });
    }

    private _resetCustomerContacts(contacts: Contact[])
    {
        this.customerContacts = contacts;
    }

    SetDropDownValue(option: RequiredInfoOption, dropType: string)
    {
        switch (dropType)
        {
            case 'distribution':
                this.distributionControl.setValue(option.key);

                // Filter Region/Site by parent key
                var parentKey = option.key;
                this.regions = this.regionOptions.slice().filter(x => x.parentKey == parentKey);
                this.sites = this.sitesOptions.slice().filter(x => x.parentKey == parentKey);
                break;
            case 'region':
                this.regionControl.setValue(option.key);
                break;
            case 'site':
                this.siteControl.setValue(option.key);
                break;
        }
    }

    openContactDialog(contactType: string): void
    {
        if (contactType == "customer")
        {
            this.contacts = this.data.subscription.customerContact;
        }
        else
        {
            this.contacts = this.data.subscription.payerContact;
        }
        const dialogRef = this.contactDialog.open(ContactSelectorComponent, {
            width: '90vw',
            data: {
                currentUserContact: this.selectedCustomerContact,
                customerContacts: this.contacts
            }
        });
        dialogRef.afterClosed().subscribe({
            next: result =>
            {
                if (result !== undefined)
                {
                    if (contactType == "customer")
                    {
                        this.selectedCustomerContact = result.contact;
                        this.contactEmailControl.setValue(this.selectedCustomerContact.email);
                        this.selectedCustomerContact.customerId = this.data.subscription.crmCustomerId;
                    }
                    else
                    {
                        this.selectedDistributorContact = result.contact;
                        this.distributorEmailControl.setValue(result.contact.email);
                        this.distributorEmail = result.contact.email;
                        this.selectedDistributorContact.customerId = this.data.subscription.crmParentId;
                    }
                }
            },
            error: (err: HttpErrorResponse) =>
            {
                this.errorHandler.handleError(err);
            }
        });
    }

    SetDate(event: MatDatepickerInputEvent<Date>): void
    {
        if (event.value == null)
        {
            this._snackBar.open(this.translocoService.translate('schedule_details_end_date_error'), this.translocoService.translate('general_message_dismiss'))
            return;
        }

        var newDate: Date = event.value ?? new Date();
        var newEndDate: Date = event.value ?? new Date();
        var currentEndDate: Date = new Date(this.schedule.filter(x => x.id == event.targetElement.id)[0].schedule.endDate);

        if (this.isAdmin)
        {
            var yearFuture = new Date(currentEndDate);
            yearFuture = new Date(yearFuture.setFullYear(currentEndDate.getFullYear() + 1));
            var yearPast = new Date(currentEndDate);
            yearPast = new Date(yearPast.setFullYear(currentEndDate.getFullYear() - 1));

            if (newDate < yearPast || newDate > yearFuture)
            {
                this._snackBar.open(this.translocoService.translate('subscription_dashboard_page-schedule_details_end_date_admin_error'), this.translocoService.translate('general_message_dismiss'))
                return;
            }
        }
        else
        {
            // Sales structure user
            if (newDate >= currentEndDate)
            {
                this._snackBar.open(this.translocoService.translate('subscription_dashboard_page-schedule_details_end_date_sales_structure_error'), this.translocoService.translate('general_message_dismiss'))
                return;
            }
        }

        var priceIndex = this.schedule.findIndex(x =>
        {
            return x.id === event.targetElement.id;
        }) + 1;

        for (var i = priceIndex; i <= this.schedule.length - 1; i++)
        {
            this.schedule[i].schedule.startDate = newEndDate;
            newEndDate = this.schedule[i].schedule.endDate;
        }

        // Update date inside the object
        this.schedule.filter(x => x.id == event.targetElement.id).forEach(x =>
        {
            x.schedule.endDate = newDate;
        });
    }

    SaveChanges()
    {
        this.savingData = true;
        var subscriptionUpdate: UpdateSubscriptionDetails =
        {
            subscriptionId: this.data.subscription.id,
            customerContact: this.selectedCustomerContact,
            payerContact: this.selectedDistributorContact,
            payerId: this.distributionControl?.value ?? "",
            regionId: this.regionControl?.value ?? "",
            siteId: this.siteControl?.value ?? "",
            subscriptionScheduleId: this.data.subscription.scheduleId ?? "",
            schedule: [],
            productId: this.selectedProduct ?? "",
            unchargedSubscription: this.data.subscription.unchargedSubscription,
            distributorInvolved: this.distributorInvolvedControl.value ?? "N",
            erpOrderNumber: this.erpOrderNumberControl.value ?? "",
            erpBillingId: this.erpBillingIdControl.value ?? "",
            submittedBy: this.submittedByControl.value ?? ""
        };
        if (this.allowUpdateScheduleDate)
        {
            subscriptionUpdate.schedule = this.schedule;
        }
        this.subscriptionService.updateSubscription(subscriptionUpdate)
            .subscribe({
                next: subscription =>
                {
                    this.SetupButtons(false);
                    this._snackBar.open(this.translocoService.translate('subscription_dashboard_details_page-save_details'), this.translocoService.translate('general_message_dismiss'));
                    this.savingData = false;
                },
                error: (err: HttpErrorResponse) =>
                {
                    this.SetupButtons(false);
                    this.errorHandler.handleError(err);
                    this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'))
                    this.savingData = false;
                }
            });
    }

    ResendNewSubscriptionEmail(customerEmail: boolean)
    {
        this.sendingCustEmail = customerEmail;
        this.sendingThirdEmail = (customerEmail ? false : true);

        this.subscriptionService.resendNewSubscriptionEmail(this.data.subscription.id, customerEmail)
            .subscribe({
                next: emailSent =>
                {
                    if (emailSent)
                    {
                        this._snackBar.open(this.translocoService.translate('subscription_dashboard_details_page-resend_initial_email_success'), this.translocoService.translate('general_message_dismiss'));
                    }
                    else
                    {
                        this._snackBar.open(this.translocoService.translate('subscription_dashboard_details_page-resend_initial_email_error'), this.translocoService.translate('general_message_dismiss'));
                    }
                    this.sendingCustEmail = false;
                    this.sendingThirdEmail = false;
                },
                error: (err: HttpErrorResponse) =>
                {
                    this.SetupButtons(false);
                    this.errorHandler.handleError(err);
                    this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'));
                    this.sendingCustEmail = false;
                    this.sendingThirdEmail = false;
                }
            });
    }

    ConfirmCancelSubscription()
    {
        const dialogRef = this.cancelSubscriptionDialog.open(SubscriptionsCancelPageComponent,
            {
                width: '26vw',
                height: '16vw',
                data: this.data.subscription.id
            });
    }

    getTCFileLink(tcFile: string, subscriptionId: string)
    {
        if (tcFile)
        {
            this.tcLink = environment.customTermsAndConditionsUrl + "/" + tcFile;
            this.tcVersion = "custom";
        }
        else
        {
            this.isLoadingTC = true;
            this.subscriptionService.getTCFileFromSubscription(subscriptionId).subscribe({
                next: file =>
                {
                    if (file)
                    {
                        this.tcLink = environment.termsAndConditionsUrl + "/" + this.activeLang + "/" + file.fullPath;
                        this.tcVersion = file.version;
                        this.isLoadingTC = false;
                        this.tcFileFound = true;
                    }
                    else
                    {
                        this.isLoadingTC = false;
                    }
                },
                error: (err: HttpErrorResponse) =>
                {
                    this.errorHandler.handleError(err);
                    this.isLoadingTC = false;
                }
            })
        }
    }

    getDistributor(crmId: string)
    {
        this.customerService.getDistributors(crmId).subscribe({
            next: dist =>
            {
                if (dist)
                {
                    this.distributor = dist[0]
                }
            },
            error: (err: HttpErrorResponse) =>
            {
                this.errorHandler.handleError(err);
            }
        })
    }

    SetDistributorInvolved(involved: string)
    {
        this.distributorInvolvedControl.setValue(involved);
    }

    SetERPOrderNbr(event: Event): void
    {
        const newValue = (event.target as HTMLInputElement).value;
        this.erpOrderNumberControl.setValue(newValue);
    }

    SetBillingId(event: Event): void
    {
        const newValue = (event.target as HTMLInputElement).value;
        this.erpBillingIdControl.setValue(newValue);
    }

    SetSubmittedBy(event: Event): void
    {
        const newValue = (event.target as HTMLInputElement).value;
        this.submittedByControl.setValue(newValue);
    }

    ngAfterViewInit(): void {
    }

    public SavePDF(): void {
        var fileName = this.data.customer.crmId + "-" + this.data.customer.name
        var fileNameFormatted = fileName.replace(" ", "_").toLowerCase() + ".pdf"
        var element = document.getElementById("subDetails");
        var pdf = new jsPDF('p','mm', 'a4');
        pdf.html(element!, {
            callback: function (pdf) {
                pdf.save(fileNameFormatted)
            },
            margin:[4,4,4,4],
            width: 216,
            windowWidth:1080,
            html2canvas: {
                scale: 0.175,
                windowHeight: 1920
            }
        }) 
    }
    
    ResendCSEmail()
    {
        const dialogRef = this.cancelSubscriptionDialog.open(SubscriptionsDashboardCsEmailComponent,
            {
                width: '26vw',
                height: '12vw',
                data: this.data.subscription.id
            });
    }

    ResubmitERPOracleOrder(){
        const dialogRef = this.resubmitERPOrderDialog.open(ConfirmErpOrderResubmitPageComponent,
            {
                width: '27vw',
                height: '14vw',
                disableClose: true,
                data: this.data.subscription.id
            });
            dialogRef.afterClosed().subscribe({
                next: result =>
                {
                    if (result != ""){
                        this.data.subscription.erpOrderNumber = result;
                        this.ResubmitOrderAvailable();
                    }
                },
                error: (err: HttpErrorResponse) =>
                {
                    this.errorHandler.handleError(err);
                }
            });
    }
}