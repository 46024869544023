<app-toolbar [pageName]="t('admin_user_association_page-title')" *transloco="let t"></app-toolbar>
<div class="main">

    <mat-card class="filter-card">
        <mat-card-content>
            <mat-form-field appearance="fill" class="searchbar">
                <mat-label><span *transloco="let t">{{ t('admin_user_association_page-active_search') }}</span></mat-label>
                <input matInput type="text" (keyup)="applyUserFilter($event)" [disabled]="showLoading">
                <mat-icon matSuffix>
                    search
                </mat-icon>
                <div *ngIf="showSearchLoading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-form-field>
        </mat-card-content>     
        <mat-card-content>
            <button mat-raised-button color="primary" type="button" class="navButton" (click)="ExportList()">                
                <mat-icon class="toolbar-icon">cloud_download</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_user_association_page-export_button') }}</span>             
            </button>            
            <button mat-raised-button type="button" class="navButton addButton" (click)="AddAssociation()">                
                <mat-icon class="toolbar-icon">add</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_user_association_page-add_button') }}</span>
            </button>
            <button mat-raised-button color="warn" [disabled]="deleteButtonDisabled" type="button" class="navButton" (click)="DeleteAssociations()">                
                <mat-icon class="toolbar-icon">delete</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_user_association_page-delete_button') }}</span>
            </button>            
        </mat-card-content>
        <mat-card-content>
            <div *ngIf="showLoadingButtons">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="mat-elevation-z8 table-div">
        <div class="table-div-inner">
        
            <table mat-table [dataSource]="displayedPage" class="recordsTable" >

                <ng-containter matColumnDef="checked">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox *transloco="let t" (change)="CheckAll($event)">
                            {{ t('admin_user_association_page-check_all_column') }}
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        <mat-checkbox [(ngModel)]="record.checked" (change)="SetCheckedRecord($event, record.userEmail, record.customerId);"></mat-checkbox>
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="userEmail">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('admin_user_association_page-email_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.userEmail }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="customerId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('admin_user_association_page-customer_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customerId }}
                    </td>
                </ng-containter>
                
                <ng-containter matColumnDef="createdDate">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('admin_user_association_page-created_date_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.createdDate }}
                    </td>
                </ng-containter>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"></tr>
            </table>
            <div *ngIf="showLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>            
        </div>
        <mat-paginator #paginator [length]="displayedColumns.length" 
                (page)="handlePageEvent($event)"
                [length]="records.length"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [disabled]="showLoading"
                [showFirstLastButtons]="false"
                [pageSizeOptions]="[10, 15, 30, 50, 100]"
                [hidePageSize]="false"
                ></mat-paginator>
    </div>
</div>