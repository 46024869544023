import { Component, OnInit } from '@angular/core';
import { AccountRecord } from './interfaces/account-record.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { CustomerService as PaymentCustomerService} from 'src/app/shared/payment/services/customer.service';
import { Customer as PaymentCustomer} from 'src/app/shared/payment/interfaces/customer.model';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accounts-page',
  templateUrl: './accounts-page.component.html',
  styleUrls: ['./accounts-page.component.scss']
})
export class AccountsPageComponent implements OnInit {
  customers: AccountRecord[] = [];
  displayedCustomers: AccountRecord[] = [];
  displayedPage: AccountRecord[] = [];
  pageSize: number = 5;
  pageIndex: number = 0;
  reachedEnd: boolean = false;


  displayedColumns: string[] = ['customerId', 'customerName', 'customerAddress'];
  showLoading: boolean = true;
  currentUser: User | null = null;
  filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  filterValue: string = "";
  showSearchLoading = false;

  constructor(private customerService: CustomerService, 
    private paymentCustomerService: PaymentCustomerService,
    private errorHandler: ErrorHandlerService,
    private router: Router) { }

  ngOnInit(): void {
    this.getCustomers("");
    this.getCustomerFilter();
  }


  getCustomers(startAfterCustomerId: string): void{
    this.showLoading = true;
    this.paymentCustomerService.getCustomers(startAfterCustomerId, 100)
    .subscribe({
      next: customers=> {
        this.getCrmCustomers(customers);
        this.customers = this.customers.concat(customers.map(customer=>{
          return {customer: null, paymentCustomer: customer, subscriptions: []}
        })).slice();
        if(customers.length == 0 || (customers.length > this.pageIndex*this.pageSize && customers.length < (this.pageIndex+1)*this.pageSize)){
          this.reachedEnd = true;
        }
      },
      error: (err: HttpErrorResponse) =>{
      this.errorHandler.handleError(err);
      }
    });
  }

  getCrmCustomers(paymentCustomers: PaymentCustomer[]): void{
    this.customerService.getCustomersByIds(paymentCustomers.map(customer=> customer.crmId)).subscribe({
      next: customers=> {
        for(var i= 0; i<customers.length; i+=1){
          var record = this.customers.find(record=> record.paymentCustomer?.crmId == customers[i].id);
          if(record != null){
            record.customer = customers[i];
          }
        }
        this.displayedCustomers = this._customerFilter(this.filterValue).slice();
        this.getCurrentPage();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  loadMoreCustomers(): void{
    var lastRecordId = this.customers[this.customers.length-1].paymentCustomer?.id;
    if(lastRecordId != null){
      this.getCustomers(lastRecordId);
    }
  }

  getCustomerFilter(){
    this.filterValue$.pipe(
      debounceTime(3000),
      distinctUntilChanged(),
      filter(value=> value.length >=2),
    ).subscribe(filterValue=>{
      this.showSearchLoading = false;
      this.filterValue = filterValue;
      this.displayedCustomers = this._customerFilter(filterValue).slice();
      this.getCurrentPage();
    })
   
  }

  applyCustomerFilter(event: Event){
    this.showSearchLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValue$.next(filterValue.trim().toLowerCase());
    
  }

  private _customerFilter(value: string): AccountRecord[] {
    if(value.length <3){
      return this.customers;
    }
    else{
      const filterValue = value.toLowerCase();

      return this.customers.filter(record => record.paymentCustomer?.crmId.toLowerCase().includes(filterValue) 
        || record?.customer?.name.toLowerCase().includes(filterValue));
    }
  }

  goToAccount(record: AccountRecord): void{
    this.router.navigate(['/account',record?.customer?.id]);
  }

  handlePageEvent(e: PageEvent) {
    if(this.pageSize != e.pageSize){
      this.pageIndex = 0;
      this.pageSize = e.pageSize;
    }else{
      this.pageIndex = e.pageIndex;
    }
    this.getCurrentPage();

  }

  getCurrentPage(): void{
    var startIndex = this.pageIndex*this.pageSize;
    var endIndex = this.pageIndex*this.pageSize+this.pageSize;
    this.displayedPage = this.displayedCustomers.slice(startIndex, endIndex > this.displayedCustomers.length ? this.displayedCustomers.length : endIndex);
    if(!this.reachedEnd && (this.pageIndex*this.pageSize) >= this.displayedCustomers.length-this.pageSize){
      this.pageIndex = this.pageIndex;
      this.loadMoreCustomers();
    }else{
      this.showLoading = false;
    }
  }

}
