<app-toolbar [pageName]="t('past_due_page-title')" *transloco="let t"></app-toolbar>

<div class="main">
    <mat-card class="filter-card">
        <mat-card-content>
            <mat-form-field appearance="fill" class="searchbar">
                <mat-label><span *transloco="let t">{{ t('past_due_page-customer_search') }}</span></mat-label>
                <input matInput type="text" (keyup)="applyFilter($event)" [disabled]="showLoading" >
                <mat-icon matSuffix>
                    search
                </mat-icon>
                <div *ngIf="showSearchLoading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <div class="mat-elevation-z8 table-div">
        <div class="table-div-inner">
        
            <table mat-table [dataSource]="displayedPage" class="recordsTable" >

                <ng-containter matColumnDef="crmId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-customer_id_num') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customer.crmId ?? "" }}
                    </td>
                </ng-containter>
        
                <ng-containter matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-company_name') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customer.name ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-country') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customer.country ?? "" }}
                    </td>
                </ng-containter>
        
                <ng-containter matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-subscription_status') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.status | uppercase }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="erpBillingId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-erp_billing_id') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.erpBillingId ?? "" }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="crmParentId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-paying_for_crm_id') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.crmParentId ?? "" }} 
                    </td>
                </ng-containter>                

                <ng-containter matColumnDef="parentName">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-paying_for_name') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.parentName ?? "" }} 
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-subscription_description') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.description ?? "" }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="submittedBy">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-submitted_by') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.submittedBy ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-created_date') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.created | date:'fullDate' }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('past_due_page-subscription_id') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.id ?? "" }} 
                    </td>
                </ng-containter>           
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow" (click)="openSubscriptionInvoicesDialog(row)"></tr>
            </table>
            <div *ngIf="showLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            
        </div>
        <mat-paginator #paginator [length]="displayedColumns.length" 
                (page)="handlePageEvent($event)"
                [length]="displayedRecords.length"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [disabled]="showLoading"
                [showFirstLastButtons]="false"
                [pageSizeOptions]="[10, 15, 30]"
                [hidePageSize]="false"
                ></mat-paginator>
    </div>
</div>