import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Payment } from '../interfaces/payment.model';
import { Invoice } from '../interfaces/Invoice.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _baseUrl = environment.apiBaseURL + "Payment/Payment";
  constructor(private http: HttpClient) { }

  getPayments(): Observable<Payment[]>{
    return this.http.get<Payment[]>(this._baseUrl+"/List", {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getUpcomingDetails(payment: Payment): Observable<Payment>{
    return this.http.post<Payment>(this._baseUrl+"/UpcomingDetails", payment, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getInvoicesBySubscriptionId(subscriptionId: string): Observable<Invoice[]>{
    return this.http.post<Invoice[]>(this._baseUrl+"/GetPaymentsBySubscriptionId?subscriptionId=" + subscriptionId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  ResubmitFailedOrder(invoiceId: string): Observable<Invoice>{
    return this.http.post<Invoice>(this._baseUrl+"/ResubmitFailedOrder?invoiceId=" + invoiceId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
