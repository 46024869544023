import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/shared/customer/interfaces/customer.model';
import { Product } from 'src/app/shared/subscription/interfaces/product.model';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { ProductService } from 'src/app/shared/subscription/services/product.service';
import { UserService } from 'src/app/shared/user/services/user.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged, EMPTY, filter, map, merge, of, switchMap } from 'rxjs';
import { CustomerDisplayPipe } from 'src/app/shared/customer/pipes/customer-display.pipe';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductCategory } from 'src/app/shared/subscription/interfaces/product-category.model';
import { Contact } from 'src/app/shared/customer/interfaces/contact.model';
import { ContactBuildPipe } from 'src/app/shared/customer/pipes/contact-build.pipe';
import { RequiredInfo } from 'src/app/shared/subscription/interfaces/required-info.model';
import { MatDialog } from '@angular/material/dialog';
import { ContactSelectorComponent } from '../contact-selector/contact-selector.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { Subscription } from 'src/app/shared/subscription/interfaces/subscription.model';
import { ContactService } from 'src/app/shared/customer/services/contact.service';
import { Language } from 'src/app/shared/language/interfaces/language.model';
import { AVAILABLE_LANGUAGES } from 'src/app/shared/language/constants/available-languages';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { RequiredInfoOption } from 'src/app/shared/subscription/interfaces/required_info_option.model';
import { Price } from 'src/app/shared/subscription/interfaces/price.model';

import { SubscriptionCreationSubmission } from 'src/app/shared/subscription/interfaces/subscription-creation-submission.model';
import { PriceService } from 'src/app/shared/subscription/services/price.service';
import { CustomerDropdown } from 'src/app/shared/payment/interfaces/customer-dropdowns.model';
import { DistributionRegionSite } from 'src/app/shared/payment/interfaces/distribution-region-site.model';

import { CurrencySymbolPipe } from 'src/app/shared/subscription/pipes/currency-symbol.pipe';
import { UserAssociationConfirmationComponent } from '../user-association-confirmation/user-association-confirmation.component';

@Component({
  selector: 'app-new-subscription-page',
  templateUrl: './new-subscription-page.component.html',
  styleUrls: ['./new-subscription-page.component.scss']
})
export class NewSubscriptionPageComponent implements OnInit {
  availableLangs: Language[] = AVAILABLE_LANGUAGES;
  currentDate: Date = new Date();
  currentUserContact!: Contact;
  customerControl = new FormControl<string | Customer>('', Validators.required);
  distributorSearchControl = new FormControl<string | Customer>('', Validators.required);
  filteredCustomers: Customer[] = [];

  paymentSource: string = "customer";
  distributors: Customer[] = [];
  distributorsFilter: Customer[] = [];
  loadingDistributors: boolean = false;
  loadedDistributors: boolean = false;
  importers: Customer[] = [];
  loadingImporters: boolean = false;
  loadedImporters: boolean = false;
  payingCustomerControl = new FormControl<string | Customer>('customer', Validators.required);

  msoDependants: Customer[] = [];
  filteredMSODependants: Customer[] = [];
  chipMSODependants: Customer[] = [];
  selectedMSODependantsControl = new FormControl<[] | Customer[]>([]);
  loadingMSODependants: boolean = false;
  loadedMSODependants: boolean = false;

  productControl = new FormControl<string | Product>({ value: '', disabled: true }, Validators.required);
  productCategories: ProductCategory[] = [];
  selectedProduct!: Product;
  selectedProductEnabledForDistributorPayment = false;
  customerPOControl = new FormControl<string>('', Validators.maxLength(100));
  installDateControl = new FormControl<Date>(new Date());
  shipDateControl = new FormControl<Date>(new Date());
  minDate: Date = new Date();
  installerControl = new FormControl<string>('');
  tcUploadControl = new FormControl<string>('');
  shipDateQuestionControl = new FormControl<string>('');
  distSaleControl = new FormControl<string>('');
  // hardwareShippingOption = new FormControl<string>('');
  showTCUpload: boolean = false;
  tcFile: File = {} as File;
  tcFileValid: boolean = false;
  tcLinkToFile: string = "";
  previousTCFile: string = "";
  filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  filterValue: string = "";

  qtyControl = new FormControl(1, [Validators.max(100), Validators.min(1)]);

  requiredInfo: RequiredInfo[] = [];

  requiredInfoFilledControl = new FormControl<boolean>(false, Validators.requiredTrue);

  hardwareReceived!: boolean;
  hardwareAlreadyReceivedFilledControl = new FormControl<boolean>(false, Validators.requiredTrue);

  languageControl = new FormControl<string>('', Validators.required);

  scheduleStepOptions: { steps: Price[], currency: string }[] = [];
  scheduleSteps: BehaviorSubject<Price[]> = new BehaviorSubject<Price[]>([]);
  selectedScheduleSteps: Price[] = [];

  currencyOptions: Price[] = [];
  currencyControl = new FormControl<string>('', Validators.required);

  contactControl = new FormControl<string | Contact>('', Validators.required);
  customerContacts: Contact[] = [];
  selectedContact!: Contact;
  selectedContactType: string = '';

  contactDistributorControl = new FormControl<string | Contact>('null', Validators.required);
  distributorContacts: Contact[] = [];
  selectedDistributorContact: Contact = {
    id: '',
    firstName: '',
    lastName: '',
    email: ''
  }
  selectedDistributorContactType: string = '';

  selectedCustomer!: Customer;
  selectedPayingCustomer!: Customer;
  createdSubscription!: Subscription[];

  submittedSubscription: boolean = false;
  submissionComplete: boolean = false;
  loadingCustomers: boolean = false;
  loadingSubscription: boolean = false;
  loadingProducts: boolean = false;
  loadingContacts: boolean = false;

  billingIds: string[] = [];
  selectedBillingId: string = "";
  manualBillingId: boolean = false;

  billingIds_Distributor: string[] = [];
  selectedBillingId_Distributor: string = "";
  manualBillingId_Distributor: boolean = false;

  showShipDate: boolean = false;

  defaultHardwareShipped: string = ""

  selectedLanguage: string = "";
  selectedCurrency!: Price;

  newSubscriptionForm = new FormGroup({
    customerControl: this.customerControl,
    payingCustomerControl: this.payingCustomerControl,
    productControl: this.productControl,
    requiredInfoFilledControl: this.requiredInfoFilledControl,
    hardwareAlreadyReceivedFilledControl: this.hardwareAlreadyReceivedFilledControl,
    languageControl: this.languageControl,
    currencyControl: this.currencyControl,
    contactControl: this.contactControl,
    contactDistributorControl: this.contactDistributorControl,
    selectedMSODependantsControl: this.selectedMSODependantsControl,
    subscriptionQtyControl: this.qtyControl,
    customerPOControl: this.customerPOControl,
    installerControl: this.installerControl,
    distSaleControl: this.distSaleControl,
    shipDateQuestionControl: this.shipDateQuestionControl
    // hardwareShippingOption: this.hardwareShippingOption
  });

  searchText: string = "";

  constructor(private userService: UserService,
    private customerService: CustomerService,
    private productService: ProductService,
    private subscriptionService: SubscriptionService,
    private priceService: PriceService,
    private customerDisplayPipe: CustomerDisplayPipe,
    private contactBuildPipe: ContactBuildPipe,
    private contactDialog: MatDialog,
    private contactService: ContactService,
    private errorHandler: ErrorHandlerService,
    private translocoService: TranslocoService,
    private currencySymbolPipe: CurrencySymbolPipe,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getFilteredCustomers();
    this.getSelectedProduct();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.installDateControl.setValue(this.minDate);
    this.installDateControl.setValidators([Validators.required]);
    this.shipDateControl.setValue(this.minDate);
    this.shipDateControl.setValidators([Validators.required]);
    //this.getCurrentUserContact();
    this.getUserFilter();
  }

  private _setFormGroupValidations(isMSOHQ: Boolean) {
    if (isMSOHQ) {
      this.newSubscriptionForm.controls["selectedMSODependantsControl"].setValidators(Validators.required);
      this.newSubscriptionForm.controls["selectedMSODependantsControl"].updateValueAndValidity();
    } else {
      this.newSubscriptionForm.controls["selectedMSODependantsControl"].clearValidators();
      this.newSubscriptionForm.controls["selectedMSODependantsControl"].updateValueAndValidity();
    }
  }

  getSelectedProduct() {
    var valueChange = this.productControl.valueChanges;
    //set selected product and set if HardwareAlreadyReceived needs to be filled
    valueChange.subscribe(product => {
      if (product != null && typeof product != 'string') {
        this.selectedProduct = product;
        this.selectedProductEnabledForDistributorPayment = product.distributorPaymentEnabled;
        if (!product.distributorPaymentEnabled && this.paymentSource != 'customer' && this.paymentSource != 'msohq' && this.paymentSource != 'importer') {
          this.SetPaymentSource('customer')
        }
        this.hardwareAlreadyReceivedFilledControl.setValue(product.includesHardware != 'Y'); //set optional field
        this.updateCurrencyOptions();

        this.currencyControl.reset();
      }
    });

    //set up additionalInfo if product has required information
    valueChange.pipe(map(product => {
      return (typeof product === 'string' || product === null ? [] : product.requiredInfo)
    })).subscribe({
      next: requiredInfo => this._resetRequiredInfo(requiredInfo),
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });


    //get productPrices
    merge(this.payingCustomerControl.valueChanges, valueChange).pipe(switchMap(() => {
      var product = this.productControl.value;
      if (product != null && typeof product != 'string') {
        return this.priceService.getPrices(product);
      }
      else {
        return of([]);
      }
    })).subscribe(prices => {
      if (this.selectedProduct != null && typeof this.selectedProduct != 'string') {
        this.selectedProduct.prices = prices;
        this.updateCurrencyOptions();

        this.scheduleStepOptions = [];
        prices.forEach(price => {
          if (this.paymentSource == 'msohq' && price.paymentSourceType == 3) {
            var matchingStep = this.scheduleStepOptions.findIndex(step => step.currency == price.currency);
            if (matchingStep != -1) {
              this.scheduleStepOptions[matchingStep].steps.push(price);
            } else {
              this.scheduleStepOptions.push({ steps: [price], currency: price.currency })
            }
          }
          else if (this.paymentSource == 'distributor' && price.paymentSourceType == 2) {
            var matchingStep = this.scheduleStepOptions.findIndex(step => step.currency == price.currency);
            if (matchingStep != -1) {
              this.scheduleStepOptions[matchingStep].steps.push(price);
            } else {
              this.scheduleStepOptions.push({ steps: [price], currency: price.currency })
            }
          }
          else if (this.paymentSource == 'customer' && price.paymentSourceType == 1) {
            var matchingStep = this.scheduleStepOptions.findIndex(step => step.currency == price.currency);
            if (matchingStep != -1) {
              this.scheduleStepOptions[matchingStep].steps.push(price);
            } else {
              this.scheduleStepOptions.push({ steps: [price], currency: price.currency })
            }
          }
          else if (this.paymentSource == 'importer' && price.paymentSourceType == 4) {
            var matchingStep = this.scheduleStepOptions.findIndex(step => step.currency == price.currency);
            if (matchingStep != -1) {
              this.scheduleStepOptions[matchingStep].steps.push(price);
            } else {
              this.scheduleStepOptions.push({ steps: [price], currency: price.currency })
            }
          }
          else if (this.paymentSource == 'importerEndUser' && price.paymentSourceType == 5) {
            var matchingStep = this.scheduleStepOptions.findIndex(step => step.currency == price.currency);
            if (matchingStep != -1) {
              this.scheduleStepOptions[matchingStep].steps.push(price);
            } else {
              this.scheduleStepOptions.push({ steps: [price], currency: price.currency })
            }
          }
        });
        if (this.selectedCustomer.currency != "" && this.currencyOptions?.filter(x => x.currency == this.selectedCustomer.currency).length > 0) {
          this.selectedCurrency = this.currencyOptions.filter(x => x.currency == this.selectedCustomer.currency)[0];
          this.SetCurrency(this.selectedCurrency);
        }
      }
    });
  }

  toggleProductCategory(index: number) {
    this.productCategories[index].expanded = !this.productCategories[index].expanded;
  }

  private updateCurrencyOptions(): void {
    var currencyOptions;
    let hasCurrencyChanged = true;
    if (!this.selectedProduct.unchargedProduct) {
      if (this.payingCustomerControl.value == 'customer') {
        this.currencyOptions = this.selectedProduct.prices.filter(price => price.default).slice();
      } else if (this.payingCustomerControl.value == 'msohq') {
        const msoDependantsControl = this.selectedMSODependantsControl.value as Customer[];

        if ((msoDependantsControl.length - (msoDependantsControl.filter(cond => cond.id == "-1").length)) >= 10) {
          currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'MSO Multiple Location Discount').slice();
        } else {
          currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'Promo MSO Price').slice();
        }

        if (currencyOptions.length == 0) {
          currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'Standard MSO Price').slice();
        }

        if (this.selectedScheduleSteps.length > 0 && currencyOptions.filter(cond => cond.id == this.selectedScheduleSteps[0].id).length > 0) {
          hasCurrencyChanged = false;
        }

        this.currencyOptions = currencyOptions;
      } else if (this.paymentSource == "importerEndUser") {
        currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'Paid by Importer').slice();
        this.currencyOptions = currencyOptions;
      } else if (this.paymentSource == "importer") {
        currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'Promo Importer Price').slice();
        if (currencyOptions.length == 0) {
          currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'Standard Importer Price').slice();
        }

        this.currencyOptions = currencyOptions;
      } else {
        currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'Promo Distributor Price').slice();
        if (currencyOptions.length == 0) {
          currencyOptions = this.selectedProduct.prices.filter(price => price.description == 'Standard Distributor Price').slice();
        }

        this.currencyOptions = currencyOptions;
      }

      if (hasCurrencyChanged)
        this.selectedScheduleSteps = [];
      this.currencyControl.setValidators([Validators.required]);
      this.currencyControl.updateValueAndValidity();
    }
    else {
      this.currencyControl.clearValidators();
      this.currencyControl.updateValueAndValidity();
    }
  }

  getFilteredCustomers(): void {
    const filteredCustomers = this.customerControl.valueChanges

    filteredCustomers.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(value => typeof value === 'string' && value.length >= 2),
      switchMap(value => {
        if (value != null && typeof value === 'string' && value.length >= 2) {
          this.loadingCustomers = true;
          return this.customerService.getCustomersForUser(value);
        } else {
          return EMPTY;
        }
      })
    ).subscribe({
      next: customers => {
        this.filteredCustomers = customers;
        this.loadingCustomers = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  public selectCustomer(customer: Customer): void {
    this.selectedCustomer = customer;
    this.customerControl.disable();
    this.loadingProducts = true;
    this.SetLanguage(this.selectedCustomer.customerLanguage ?? "");
    this.productService.getProducts(customer).subscribe({
      next: productGroups => {
        if (productGroups.length > 0) {
          this._resetProductControl(productGroups);
          this.loadingProducts = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });



    this.loadingContacts = true;
    this.contactService.getContacts(customer.id).subscribe({
      next: contacts => {
        this.selectedCustomer.contacts = contacts;
        this._resetCustomerContacts(contacts);
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });

    this.customerService.populateCustomerBillingIds(customer).subscribe({
      next: resultCustomer => {
        this.billingIds = resultCustomer.billingIds;
        this.manualBillingId = (this.billingIds[0] == "MANUAL");
        this.selectedBillingId = this.manualBillingId ? "MANUAL" : "";
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  public IsDistributorPaymentAllowedForCustomer(): Boolean {
    if (this.selectedCustomer == null)
      return true;

    return this.selectedCustomer.customerRule.allowDistributorPayment;
  }

  public SetPaymentSource(source: string): void {
    this.paymentSource = source;
    // this.hardwareShippingOption.clearValidators();
    if (source == "customer" || source == "importer") {
      //TODO handle customerPaymentSettings here
      this.payingCustomerControl.setValue(source);
      this._setFormGroupValidations(false);

      if (this.contactDistributorControl.value == '') {
        this.contactDistributorControl.setValue('null');//distributorContact no longer needed
      }
    } else if (source == "distributor") {
      this.payingCustomerControl.setValue("");
      // this.hardwareShippingOption.setValidators(Validators.required);
      // this.hardwareShippingOption.updateValueAndValidity();
      this._setFormGroupValidations(false);

      if (!this.loadingDistributors && !this.loadedDistributors) {
        this.loadingDistributors = true;
        this.customerService.getDistributors(this.selectedCustomer.id).subscribe({
          next: resultDistributors => {
            this.distributors = resultDistributors;
            this.distributorsFilter = resultDistributors;
            this.loadingDistributors = false;
            this.loadedDistributors = true;
            if (this.contactDistributorControl.value == 'null') {
              this.contactDistributorControl.setValue(''); //distributorContact is needed
            }
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
          }
        })
      }
    } else if (source == "msohq") {
      this.payingCustomerControl.setValue("msohq");
      this._setFormGroupValidations(true);

      if (this.contactDistributorControl.value == '') {
        this.contactDistributorControl.setValue('null');//distributorContact no longer needed
      }

      if (!this.loadingMSODependants && !this.loadedMSODependants) {
        this.loadingMSODependants = true;

        this.customerService.getMSODependancies(this.selectedCustomer.id).subscribe({
          next: resultMSODependants => {
            this.msoDependants = [];
            this.msoDependants.push({
              id: '-1',
              name: this.translocoService.translate('new_subscription_page-select_all_mso_locations'),
              status: '',
              address: { city: '', countryCode: '', line1: '', line2: '', postalCode: '', state: '' },
              contacts: [],
              productCategories: [],
              billingIds: [],
              customerRule: { allowDistributorPayment: true, allowMSOHQPayment: true, allowImporterPayment: true, isMSOHQ: false },
              availableQty: -1
            });

            resultMSODependants.forEach(msoDependant => {
              this.msoDependants.push({ ...msoDependant });
            })

            this.filteredMSODependants = this.msoDependants;

            this.loadingMSODependants = false;
            this.loadedMSODependants = true;
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
          }
        })
      }
    } else if (this.paymentSource == "importerEndUser") {
      this.payingCustomerControl.setValue("");
      this._setFormGroupValidations(false);

      this.setHardwareReceived(true);
      this.defaultHardwareShipped = "Y"

      if (!this.loadingImporters && !this.loadedImporters) {
        this.loadingImporters = true;
        this.customerService.getImporters(this.selectedCustomer.id, this.selectedProduct.id).subscribe({
          next: resultImporters => {
            this.importers = resultImporters;
            this.loadingImporters = false;
            this.loadedImporters = true;
            if (this.contactDistributorControl.value == 'null') {
              this.contactDistributorControl.setValue('');
            }
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
          }
        })
      }
    }

    if (this.selectedProduct != null) {
      this.updateCurrencyOptions();
    }
  }

  public SetDistributor(distributor: Customer): void {
    this.selectedPayingCustomer = distributor;

    if (this.paymentSource == "importer") {
      this.SetBillingId("MANUAL");
    }

    if (this.paymentSource == "importerEndUser") {
      this.qtyControl.clearValidators();
      this.qtyControl.setValidators([Validators.max(this.importers.filter(x => x.id == distributor.id)[0]?.availableQty), Validators.min(1)]);
      this.qtyControl.updateValueAndValidity();
    }
    else {
      this.qtyControl.clearValidators();
      this.qtyControl.setValidators([Validators.max(100), Validators.min(1)]);
      this.qtyControl.setValue(1);
      this.qtyControl.updateValueAndValidity();
    }
    this.contactService.getContacts(distributor.id).subscribe({
      next: contacts => {
        distributor.contacts = contacts;
        this.payingCustomerControl.setValue(distributor);

        this._resetDistributorContacts(contacts);
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });

    this.customerService.populateCustomerBillingIds(distributor).subscribe({
      next: resultCustomer => {
        this.billingIds_Distributor = resultCustomer.billingIds;
        this.manualBillingId_Distributor = (this.billingIds_Distributor[0] == "MANUAL");
        this.selectedBillingId_Distributor = this.manualBillingId_Distributor ? "MANUAL" : "";
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  public CountTotalSelectedMSODependants() {
    return (this.selectedMSODependantsControl.value as Customer[]).filter(cond => cond.id != "-1").length;
  }

  public SetMSODependants(msoDependant: Customer, option: any): void {
    if (msoDependant.id == '-1') {
      if (option.tagName == 'MAT-CHIP')
        option["_selected"] = false;

      this._selectAllMSOLocations(option);
    } else if (option._selected == false || option.tagName == 'MAT-CHIP') {
      const msoDependantsControl = [...(this.selectedMSODependantsControl.value as Customer[])];
      this._removeFirst(msoDependantsControl, msoDependant);
      this.selectedMSODependantsControl.setValue(msoDependantsControl); // To trigger change detection

      const dummyMSODependant = msoDependantsControl.filter(cond => cond.id == "-1")
      if (dummyMSODependant.length == 1) {
        this._removeFirst(msoDependantsControl, dummyMSODependant[0]);
        this.selectedMSODependantsControl.setValue(msoDependantsControl); // To trigger change detection
      }
    } else if (option._selected == true) {
      const msoDependantsControl = this.selectedMSODependantsControl.value as Customer[];
      if (msoDependantsControl.length == (this.msoDependants.length - 1)) {
        option._selected = true;
        this._selectAllMSOLocations(option);
      }
    } else {
      const msoDependantsControl = this.selectedMSODependantsControl.value as Customer[];

      if (msoDependantsControl.length == 0) {
        option._selected = true;
        this._selectAllMSOLocations(option);
      }
    }

    if (msoDependant.id != '-1') {
      if (option._selected) {
        if (this.chipMSODependants.filter(x => x.id == msoDependant.id)?.length == 0) {
          this.chipMSODependants.push(msoDependant);
        }
      } else {
        this.chipMSODependants = this.chipMSODependants.filter(x => x.id != msoDependant.id);
      }
      this.selectedMSODependantsControl.setValue(this.chipMSODependants);
    }

    this.updateCurrencyOptions();
  }

  private _selectAllMSOLocations(ev: any): void {
    if (ev._selected) {
      this.selectedMSODependantsControl.setValue(this.msoDependants);
      ev._selected = true;
    }

    if (!ev._selected) {
      this.selectedMSODependantsControl.setValue([]);
    }
  }

  private _removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  public SetProduct(product: Product): void {
    this.productControl.setValue(product);
    this.setDynamicRequiredFields();
  }

  // public SetHardwareShippingOption(hardwareShippingOption: string): void {
  //   this.hardwareShippingOption.setValue(hardwareShippingOption);
  //   this.setDynamicRequiredFields();
  // }

  private _resetProductControl(categories: ProductCategory[]): void {
    if (categories.length > 0) {
      this.productCategories = categories.filter(category => category.products.length > 0);
      this.productControl.enable();
    } else {
      this.productControl.reset();
      this.productControl.disable();
    }
  }

  private _resetRequiredInfo(requiredInfo: RequiredInfo[]): void {
    this.requiredInfoFilledControl.setValue(requiredInfo.length == 0);
    if (this.selectedCustomer !== undefined &&
      this.selectedCustomer !== null
      && (this.selectedCustomer?.address?.countryCode?.toLowerCase() === "us" ||
        this.selectedCustomer?.address?.countryCode?.toLowerCase() === "ca")
    ) {

      let customer = new CustomerDropdown(this.selectedCustomer.id, this.selectedPayingCustomer?.id, this.selectedCustomer?.address?.state, this.selectedCustomer?.address?.countryCode);
      this.customerService.getCustomerDropdownValues(customer).subscribe({
        next: dropdownValues => {
          this.requiredInfo.forEach(info => {
            let defaultOption = this.GetDefaultValue(info, dropdownValues);
            info.defaultValue = defaultOption.value;
            if (defaultOption.value !== "") {
              this.SetRequiredInfoDropdown(info, defaultOption);
            }
          })
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
      requiredInfo.sort((a, b) => a.type - b.type)
    }
    requiredInfo.forEach(info => {
      info.value = "";
      if (info.format == 1) {
        info.visibleOptions = this.GetDisplayedRequiredInfo(info);
      }
    });
    this.requiredInfo = requiredInfo;
  }

  public SetRequiredInfoDropdown(requiredInfo: RequiredInfo, option: RequiredInfoOption) {
    if (requiredInfo.format == 1) {
      var requiredInfoCompleted = true;
      this.showShipDate = false;
      this.requiredInfo.forEach(info => {
        if (info.key == requiredInfo.key) {
          info.value = option.value;
          info.selectedOptionKey = option.key
        }
        if (info.value === "" || (info.format == 2 && !info.value.match(info.pattern))) {
          requiredInfoCompleted = false;
        }
        if (info.format == 1 && info.parent == requiredInfo.key) {
          info.visibleOptions = this.GetDisplayedRequiredInfo(info);
        }
      });
      this.requiredInfoFilledControl.setValue(requiredInfoCompleted);
    }
  }

  public SetRequiredInfo(thisInfo: RequiredInfo, event: any) {
    var requiredInfoCompleted = true;
    var value = event.target.value;
    if (thisInfo.format == 2) {
      this.requiredInfo.forEach(info => {
        if (info.key == thisInfo.key) {
          info.value = value;
        }
        if (info.value === "" || (info.format == 2 && !value.match(info.pattern))) {
          requiredInfoCompleted = false;
        }
      });
      this.requiredInfoFilledControl.setValue(requiredInfoCompleted);
    }
  }

  public setHardwareReceived(received: boolean): void {
    this.hardwareAlreadyReceivedFilledControl.setValue(true);
    this.hardwareReceived = received;
    this.setDynamicRequiredFields()
  }

  public setQuantity(event: any): void {
    if (event.target.value == null || event.target.value == 0) {
      this.qtyControl.setValue(1);
    }
    else {
      this.qtyControl.setValue(event.target.value);
    }
  }

  public SetcustomerPO(event: any): void {
    if (event.target.value == null) {
      this.customerPOControl.setValue(event.target.value);
    }
  }

  public SetInstallDate(event: any): void {
    if (event.target.value == null) {
      this._snackBar.open(this.translocoService.translate('requested_install_date_error'), this.translocoService.translate('general_message_dismiss'))
      return;
    }
    else {
      var installDate = new Date(event.target.value);
      this.installDateControl.setValue(installDate);
    }
  }

  public SetInstaller(installer: string): void {
    this.installerControl.setValue(installer);
  }

  public SetTCUpload(tcUpload: string): void {
    this.tcUploadControl.setValue(tcUpload);
    this.showTCUpload = tcUpload == "Y" ? true : false;
  }

  public ValidateTCFile(event: any): void {
    var file: File = event.target.files[0];

    if (file) {
      if (file.size > 20000000) {
        this._snackBar.open(this.translocoService.translate('new_subscription_page-tc_upload_size_limit_error'), this.translocoService.translate('general_message_dismiss'))
        this.tcFileValid = false;
        return;
      }
      else {
        this.tcFileValid = true;
        this.tcFile = file;
      }
    }
  }

  public UploadTCFile(event: any) {
    var fileName = this.tcFile.name.split(".")[0];
    var extension = this.tcFile.name.split(".")[1];
    var formData = new FormData();
    formData.append('FileName', this.selectedCustomer.id + "_" + this.selectedProduct.partNumber.toLowerCase().replace(new RegExp("[^A-Za-z0-9]", "g"), ""));
    formData.append('TCFile', this.tcFile);
    formData.append("StripeAccount", "1");
    formData.append("CountryCode", this.selectedCustomer.address.countryCode);
    formData.append("Version", "1");
    formData.append("Extension", extension);
    formData.append("PreviousFileName", (this.tcLinkToFile != "" ? this.tcLinkToFile.split("/")[this.tcLinkToFile.split("/").length - 1] : ""));
    this.subscriptionService.uploadTCFile(formData)
      .subscribe({
        next: path => {
          this.tcLinkToFile = path.path;
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
  }

  public RemoveTCFile() {
    var formData = new FormData();
    formData.append('FileName', this.tcLinkToFile);
    formData.append('TCFile', this.tcFile);
    formData.append("StripeAccount", "x");
    formData.append("CountryCode", this.selectedCustomer.address.countryCode);
    formData.append("Version", "x");
    formData.append("Extension", this.tcLinkToFile);
    formData.append("PreviousFileName", (this.tcLinkToFile != "" ? this.tcLinkToFile.split("/")[this.tcLinkToFile.split("/").length - 1] : ""));
    this.subscriptionService.removeTCFile(formData)
      .subscribe({
        next: path => {
          this.tcLinkToFile = "";
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
  }


  public SetShipDate(event: any): void {
    if (event.target.value == null) {
      this._snackBar.open(this.translocoService.translate('new_subscription_page-ship_date_error'), this.translocoService.translate('general_message_dismiss'))
      return;
    }
    else {
      this.shipDateControl.setValue(new Date(event.target.value));
    }
  }

  public SetShipDateAnswer(answer: string): void {
    this.showShipDate = answer.toLocaleLowerCase() == "n"
      ? true : false;
    this.shipDateQuestionControl.setValue(answer);

    if (answer.toLocaleLowerCase() == "y") {
      this.shipDateControl.setValue(new Date())
    }
  }

  public SetDistSale(distSale: string): void {
    this.distSaleControl.setValue(distSale);
  }

  public GetDisplayedRequiredInfo(thisInfo: RequiredInfo): RequiredInfoOption[] { //get dropdown options filtered by parent dropdown and customer country
    if (thisInfo.format == 1) {
      var dropdown = thisInfo;
      var parentKey = dropdown.parent;
      dropdown.options = dropdown.options.filter(option => option.countries.split('|').indexOf(this.selectedCustomer.address.countryCode) != -1);
      if (parentKey != null && parentKey != "") {
        var parentInfo = this.requiredInfo.find(info => info.key == parentKey);
        if (parentInfo != null && parentInfo?.selectedOptionKey != null && parentInfo?.selectedOptionKey != "") {
          return dropdown.options.filter(option => (option.parentKey == parentInfo?.selectedOptionKey || option.parentKey == ""));
        } else {
          return [];
        }
      }
      return dropdown.options.slice();
    }
    return [].slice();

  }

  public GetDefaultValue(thisInfo: RequiredInfo, distributionRegionSites: DistributionRegionSite[]): RequiredInfoOption { //get dropdown options filtered by parent dropdown and customer country
    let emptyObject = { key: '', parentKey: '', value: '', countries: '' };
    if (thisInfo.format == 1) {
      let values = Object.values(distributionRegionSites);
      switch (thisInfo.key.toLocaleLowerCase()) {
        case "ai_distribution":
          return thisInfo.options.filter(x => x.key === String(values[0]))[0]!;
        case "ai_region":
          return thisInfo.options.filter(x => x.key === String(values[1]))[0]!;
        case "ai_site":
          return thisInfo.options.filter(x => x.key === String(values[2]))[0]!;
        default:
          return emptyObject;
      }
    }
    return emptyObject;
  }

  public SetLanguage(lang: string): void {
    if (this.availableLangs.filter(x => x.id == lang).length > 0) {
      this.languageControl.setValue(lang);
    }
    else {
      this.languageControl.setValue(this.availableLangs.filter(x => x.options?.filter(y => y == lang))[0].id);
    }
    this.selectedLanguage = this.languageControl.value ?? "";
  }

  getCurrencySymbols = (price: Price) => {
    return this.currencySymbolPipe.transform(price);
  }

  public SetCurrency(price: Price): void {
    if (price != undefined) {
      // this.loadingSubscription = true;
      // if (!this.ValidateMoonwalkSubscriptions(price.currency)) {
        this.currencyControl.setValue(price.currency);

        if (this.selectedProduct.scheduled) {
          var stepActiveIndex = this.scheduleStepOptions.findIndex(step => step.currency == price.currency);
          let currentProductSteps = this.scheduleStepOptions[stepActiveIndex].steps;

          if (this.paymentSource == 'msohq') {
            if (price.description === 'MSO Multiple Location Discount')
              currentProductSteps = currentProductSteps.filter(cond => cond.paymentSourceType == 3 && cond.amountDecimal == price.amountDecimal && cond.description == "MSO Multiple Location Discount");
            else
              currentProductSteps = currentProductSteps.filter(cond => cond.paymentSourceType == 3 && cond.description != "MSO Multiple Location Discount");
          } else if (this.paymentSource == 'distributor')
            currentProductSteps = currentProductSteps.filter(cond => cond.paymentSourceType == 2)
          else if (this.paymentSource == 'customer')
            currentProductSteps = currentProductSteps.filter(cond => cond.paymentSourceType == 1)
          else if (this.paymentSource == 'importer')
            currentProductSteps = currentProductSteps.filter(cond => cond.paymentSourceType == 4)
          else if (this.paymentSource == 'importerEndUser')
            currentProductSteps = currentProductSteps.filter(cond => cond.paymentSourceType == 5)

          this.scheduleSteps.next(currentProductSteps);
        }
      // } else {
      //   this.scheduleSteps.next(this.selectedScheduleSteps);
      // }
    }
  }

  // ValidateMoonwalkSubscriptions(currency: string): boolean {
  //   if ((localStorage.getItem("accountSelected") == "1" || localStorage.getItem("accountSelected") == "4" || localStorage.getItem("accountSelected") == "5") &&
  //     (this.selectedProduct.category == "moonwalk" || this.selectedProduct.category == "moonwalk_+_linq_bundles")) {
  //     this.customerService.getCustomerMoonWalkInformation(this.selectedProduct, this.selectedCustomer.id, currency).subscribe({
  //       next: promoPrices => {
  //         if (promoPrices.length == 0) {
  //           return false;
  //         }
  //         this.scheduleSteps.next(promoPrices);
  //         this.selectedProduct.defaultStartingPriceId = promoPrices.filter(x => x.description == "Promo Lead Time")[0].id ?? promoPrices[0].id;
  //         this.selectedProduct.defaultPriceId = promoPrices.filter(x => x.description == "Promo Price")[0].id ?? promoPrices[1].id;
  //         this.selectedProduct.prices.forEach(price => {
  //           if (price.currency == currency && price.id == this.selectedProduct.defaultPriceId) {
  //             price.default = true;
  //           }
  //           else {
  //             price.default = false;
  //           }
  //         });
  //         this.loadingSubscription = false;
  //         return true;
  //       },
  //       error: (err: HttpErrorResponse) => {
  //         this.errorHandler.handleError(err);
  //       }
  //     });
  //   }
  //   else {
  //     this.loadingSubscription = false;
  //   }
  //   return false;
  // }

  private _resetCustomerContacts(contacts: Contact[]) {
    this.customerContacts = contacts;
    if (this.selectedContactType === "customerContact") {
      this.contactControl.reset();
    }
  }

  private _resetDistributorContacts(contacts: Contact[]) {
    this.distributorContacts = contacts;
    var customer = this.payingCustomerControl.value;
    if (this.selectedDistributorContactType === "customerContact") {
      this.contactDistributorControl.setValue('');
      this.selectedDistributorContact = {
        id: '',
        firstName: '',
        lastName: '',
        email: ''
      }
    }
  }


  displayCustomer = (customer: Customer) => {
    return customer && customer?.name ? this.customerDisplayPipe.transform(customer) : '';
  }

  openContactDialog(): void {
    const dialogRef = this.contactDialog.open(ContactSelectorComponent, {
      width: '90vw',
      data: {
        currentUserContact: this.currentUserContact,
        customerContacts: this.customerContacts
      }
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result !== undefined) {
          this.loadingSubscription = true;
          this.userService.getUserCustomers(result.contact.email).subscribe({
            next: userCustomers => {
              if (userCustomers.authorizations.filter(x => x.customerId != this.selectedCustomer.id).length > 0) {
                var ids = userCustomers.authorizations.filter(x => x.customerId != this.selectedCustomer.id).map(x => x.customerId);
                this.customerService.getCustomersByIds(ids).subscribe({
                  next: customers => {
                    const dialogRef = this.contactDialog.open(UserAssociationConfirmationComponent, {
                      data: {
                        user: result.contact.email,
                        customers: customers,
                        currentCustomer: this.selectedCustomer
                      },
                      width: '60vw',
                      closeOnNavigation: false,
                      disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(selectContact => {
                      if (selectContact) {
                        this.SetContact(result);
                      }
                      this.loadingSubscription = false;
                    });
                  },
                  error: (err: HttpErrorResponse) => {
                    this.errorHandler.handleError(err);
                  }
                });
              }
              else {
                this.SetContact(result);
                this.loadingSubscription = false;
              }
            },
            error: (err: HttpErrorResponse) => {
              this.errorHandler.handleError(err);
            }
          });
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  SetContact(result: any) {
    this.contactControl.setValue(result);
    this.contactControl.markAsTouched();
    this.selectedContact = result.contact;
    this.selectedContactType = result.contactType;
  }

  openDistributorContactDialog(): void {
    const dialogRef = this.contactDialog.open(ContactSelectorComponent, {
      width: '90vw',
      data: {
        currentUserContact: this.currentUserContact,
        customerContacts: this.distributorContacts
      }
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result !== undefined) {
          this.contactDistributorControl.setValue(result.contact);
          this.contactDistributorControl.markAsTouched();
          this.selectedDistributorContact = result.contact;
          this.selectedDistributorContactType = result.contactType;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  SetBillingId(billingId: string) {
    if (!this.manualBillingId) {
      this.selectedBillingId = billingId;
    }
  }

  SetBillingId_Distributor(billingId: string) {
    if (!this.manualBillingId_Distributor) {
      this.selectedBillingId_Distributor = billingId;
    }
  }


  setSchedule(scheduleSteps: Price[]) {
    this.selectedScheduleSteps = scheduleSteps;
  }

  setDynamicRequiredFields(): void {
    if (this.selectedProduct.distSale == 'Yes' && this.paymentSource != 'distributor') {
      this.distSaleControl.setValidators([Validators.required]);
    }
    else if (this.selectedProduct.distSale == 'Yes' && this.paymentSource == 'distributor') {
      this.SetDistSale('Y')
    }
    if (this.hardwareReceived == false && (this.selectedCustomer.address.countryCode == 'US' || this.selectedCustomer.address.countryCode == 'CA')) {
      this.installerControl.setValidators([Validators.required]);
    }
    if (this.hardwareReceived == false && this.selectedProduct.shipDate == 'Yes') {
      this.shipDateQuestionControl.setValidators([Validators.required]);
    }
    if (this.selectedProduct.tcUpload == 'Y') {
      this.tcUploadControl.setValidators([Validators.required]);
    }
  }

  getUserFilter() {
    this.filterValue$.pipe(
      debounceTime(3000),
      distinctUntilChanged(),
      filter(value => value.length >= 2),
    ).subscribe(filterValue => {
      this.filterValue = filterValue;
      this.distributorsFilter = this._userFilter(filterValue).slice();
    })

  }

  applyUserFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValue$.next(filterValue.trim().toLowerCase());
    if (filterValue.length < 3) {
      this._userFilter(filterValue).slice();
    }
  }

  private _userFilter(value: string): Customer[] {
    this.loadingDistributors = true;
    this.distributorsFilter = [];
    this.distributorsFilter = this.distributors;
    if (value.length < 3) {
      this.loadingDistributors = false;
      return this.distributorsFilter.filter(record => record.status != null);
    }
    else {
      const filterValue = value.toLowerCase();
      this.loadingDistributors = false;
      return this.distributorsFilter.filter(record => record.name.includes(filterValue)
        || record.id.includes(filterValue));
    }
  }

  submitNewSubscription() {
    if (this.showTCUpload && (!this.tcFileValid || this.tcLinkToFile == "")) {
      this._snackBar.open(this.translocoService.translate('new_subscription_page-file_not_uploaded'), this.translocoService.translate('general_message_dismiss'));
    }
    else {
      this.loadingSubscription = true;
      this.submittedSubscription = true;

      if (!this.selectedProduct.scheduled && !this.selectedProduct.unchargedProduct) {
        this.selectedProduct.prices.forEach(p => {
          p.active = p.currency == this.currencyControl?.value;
        });
      }
      var submission: SubscriptionCreationSubmission = {
        contact: this.selectedContact,
        distributorContact: undefined,
        product: this.selectedProduct,
        customer: this.selectedCustomer,
        distributor: undefined,
        schedule: (this.selectedProduct.scheduled ? this.selectedScheduleSteps : []),
        language: this.languageControl?.value ?? "",
        currency: this.currencyControl?.value ?? "",
        hardwareReceived: this.hardwareReceived,
        billingId: this.selectedBillingId,
        billingId_Distributor: '',
        msoDependants: undefined,
        quantity: this.qtyControl?.value ?? 1,
        importerType: this.paymentSource == "importer" ? 6 : this.paymentSource == "importerEndUser" ? 7 : 0,
        customerPO: this.customerPOControl?.value ?? "",
        installDate: this.installDateControl.value ? this.installDateControl.value?.getDate() + "/" + (this.installDateControl.value?.getMonth() + 1) + "/" + this.installDateControl.value?.getFullYear() : "",
        installer: this.installerControl.value ?? "",
        tcFile: this.tcLinkToFile != "" ? this.tcLinkToFile.split("/")[this.tcLinkToFile.split("/").length - 1] : "",
        shipDate: this.shipDateControl?.value ? this.shipDateControl.value?.getDate() + "/" + (this.shipDateControl.value?.getMonth() + 1) + "/" + this.shipDateControl.value?.getFullYear() : "",
        distSale: this.distSaleControl.value ?? ""
        // hardwareShippingOption: this.hardwareShippingOption.value ?? ""
      };
      if (this.payingCustomerControl.value != "customer" && this.payingCustomerControl.value != "msohq" && this.payingCustomerControl.value != "importer") {
        submission.distributor = this.payingCustomerControl.value as Customer;
        submission.distributorContact = this.selectedDistributorContact;
        submission.billingId = "";
        submission.billingId_Distributor = this.selectedBillingId_Distributor;
      }
      if (this.payingCustomerControl.value == "msohq") {
        submission.msoDependants = (this.selectedMSODependantsControl.value as Customer[]).filter(cond => cond.id != "-1");
      }
      this.subscriptionService.createSubscription(submission)
        .subscribe({
          next: subscription => {
            this.loadingSubscription = false;
            this.createdSubscription = subscription;
            this.submissionComplete = true;
            if (subscription != null && subscription.length > 0) {
              this._snackBar.open(this.translocoService.translate('new_subscription_page-success') + subscription[0].description, this.translocoService.translate('general_message_dismiss'));
            }
            else {
              this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'))
            }
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
          }
        });
    }
  }
  onKey(event: any) {
    let value = (<HTMLInputElement>event.target).value;
    this.filteredMSODependants = this.search(value);
  }

  search(value: string) {
    const filter = value.toLowerCase();
    return this.msoDependants.filter(customer => {
      const customerAddress = customer?.address != null ? ", " + customer.address.city
        + ", "
        + customer.address.state
        + ", "
        + customer.address.postalCode : "";
      return (customer.name
        + "-"
        + customer.id
        + customerAddress
        + " (" + customer.status + ")").toLowerCase().includes(filter)
    });
  }

  msoSelectLostFocus() {
    this.searchText = "";
    this.filteredMSODependants = this.search(this.searchText);
  }
}
