import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { PriceService } from 'src/app/shared/subscription/services/price.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { SubscriptionDashboardRecord } from 'src/app/pages/subscriptions-dashboard-page/interfaces/subscription-dashboard-record.module'
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UpdateSubscriptionScheduleComponent } from '../update-subscription-schedule/update-subscription-schedule.component';
import { Price } from 'src/app/shared/subscription/interfaces/price.model';
import { PaidBy } from 'src/app/shared/enums/paid.by';

@Component({
  selector: 'app-update-schedule',
  templateUrl: './update-schedule.component.html',
  styleUrls: ['./update-schedule.component.scss']
})
export class UpdateScheduleComponent implements OnInit {
  paidByEnum: typeof PaidBy = PaidBy;
  displayedColumns: string[] = ['crmId', 'name', 'paidBy', 'crmParentId', 'parentName', 'country', 'id', 'description', 'status', 'submittedBy', 'created'];
  records: SubscriptionDashboardRecord[] = [];
  recordsBackup: SubscriptionDashboardRecord[] = [];
  displayedRecords: SubscriptionDashboardRecord[] = [];
  displayedPage: SubscriptionDashboardRecord[] = [];
  phases: Price[] = [];
  pageSize: number = 10;
  pageIndex: number = 0;
  reachedEnd: boolean = false;
  showLoading: boolean = false;
  showSearchLoading = false;
  filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  filterValue: string = "";
  startAfterId: string = "";
  private readonly _destroying$ = new Subject<void>();

  constructor(private subscriptionService: SubscriptionService,
    private priceService: PriceService,
    private errorHandler: ErrorHandlerService,
    private updateDialog: MatDialog) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.displayedPage = [];
    this.getSubscriptions();
    this.getFilter();
  }

  getSubscriptions() {
    this.subscriptionService.getSubscriptionSummaries(500, this.startAfterId, false).pipe(takeUntil(this._destroying$)).subscribe({
      next: customers => {
        customers.forEach(customer => {
          customer.subscriptions.forEach(subscription => {
            this.records.push({ customer: customer, subscription: subscription });
            this.recordsBackup = this.records;
            if (subscription.id.includes("sub")) {
              this.startAfterId = subscription.id;
            }
          });
        });
        this.displayedRecords = this._customerFilter(this.filterValue).slice();
        this.getCurrentPage();
        this.showLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  handlePageEvent(e: any) {
    if (this.pageSize != e.pageSize) {
      this.pageIndex = 0;
      this.pageSize = e.pageSize;
    } else {
      this.pageIndex = e.pageIndex;
    }
    this.getCurrentPage();
  }

  getCurrentPage(): void {
    var startIndex = this.pageIndex * this.pageSize;
    var endIndex = this.pageIndex * this.pageSize + this.pageSize;
    this.displayedPage = this.records.slice(startIndex, endIndex > this.records.length ? this.records.length : endIndex);
  }

  private _customerFilter(value: string): SubscriptionDashboardRecord[] {
    const filterValue = value.toLowerCase().trim();
    this.records = this.recordsBackup;
    if (filterValue === "4ll_v4lue5" || value?.length == 0) {
      return this.records;
    }
    return this.records.filter(record => record?.customer.crmId.toLowerCase().includes(filterValue)
      || record?.customer.crmId?.toLowerCase()?.includes(filterValue)
      || record?.customer.name?.toLowerCase()?.includes(filterValue)
      || record?.subscription.crmParentId?.toLowerCase()?.includes(filterValue)
      || record?.subscription.parentName?.toLowerCase()?.includes(filterValue)
      || record?.customer.country?.toLowerCase()?.includes(filterValue)
      || record?.subscription.id?.toLowerCase()?.includes(filterValue)
      || record?.subscription.description?.toLowerCase()?.includes(filterValue)
      || record?.subscription.status?.toLowerCase()?.includes(filterValue)
    )
  }

  applyFilter(event: Event) {
    this.showSearchLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValue$.next(filterValue.trim().toLowerCase());
  }

  resetRecords() {
    this.records = [].slice();
    this.displayedRecords = [].slice();
    this.displayedPage = [].slice();
    this.showLoading = false;
    this.showSearchLoading = false;
    this.filterValue$.next("4ll_v4lue5");
  }

  getFilter(): void {
    this.filterValue$.pipe(
      debounceTime(3000),
      distinctUntilChanged(),
      filter(value => value.length >= 0),
    ).subscribe(filterValue => {
      this.showSearchLoading = false;
      this.filterValue = filterValue;
      this.records = this._customerFilter(filterValue).slice();
      this.pageIndex = 0;
      this.getCurrentPage();
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  openSubscriptionInvoicesDialog(row: SubscriptionDashboardRecord): void {
    this.showLoading = true;
    this.phases = [];
    this.priceService.getPricesByProductId(row.subscription.productId).pipe(takeUntil(this._destroying$)).subscribe({
      next: phases => {
        phases.forEach(phase => {
          this.phases.push(phase);
        });

        const dialogRef = this.updateDialog.open(UpdateSubscriptionScheduleComponent,
          {
            width: '65vw',
            height: '40vw',
            data: {
              subscriptionData: row,
              phases: this.phases
            },
            autoFocus: false
          });
        this.showLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
        this.showLoading = false;
      }
    });
  }
}
