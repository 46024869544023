<app-toolbar [pageName]="t('new_subscription_page-title')" *transloco="let t"></app-toolbar>
<div class="main-div">
    <mat-card class="main-content">
        <mat-card-content class="card-content">
            <div *ngIf="loadingSubscription">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <form class="newSubscriptionForm" form="newSubscriptionForm">
                <div>
                    <div class="newSubscriptionFormSection">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-choose_customer')
                                    }}</span></mat-label>
                            <input type="text" matInput [formControl]="customerControl" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomer">
                                <mat-option *ngFor="let customer of filteredCustomers" [value]="customer"
                                    (click)="selectCustomer(customer)">
                                    {{customer | customerDisplay}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon matSuffix>
                                search
                            </mat-icon>
                            <div *ngIf="loadingCustomers">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-select_subscription')
                                    }}</span></mat-label>
                            <mat-select
                                [disabled]="loadingSubscription || submittedSubscription || selectedCustomer == undefined"
                                [panelClass]="loadingProducts ? 'hide-dummy-option' : ''">
                                <mat-optgroup *ngFor="let group of productCategories let i = index" (click)="toggleProductCategory(i)">
                                    <mat-label class="productGroupLabel"><span *transloco="let t">{{ (group.expanded==true ? '- ' : '+ ') + t('new_subscription_page-product_group-'+group.labelId)}}</span></mat-label>
                                    <mat-option *ngFor="let product of group.products" [value]="product"
                                        (click)="SetProduct(product)" [class.active]="group.expanded">
                                        {{product.name}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                            <div *ngIf="loadingProducts">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                            <mat-hint *ngIf = "selectedProduct">
                                {{selectedProduct.description}}
                            </mat-hint>
                            <!-- <mat-icon matSuffix>
                                local_mall
                            </mat-icon> -->
                        </mat-form-field>
                    </div>
                    <div *ngIf = "selectedProduct && selectedProduct.description"> <br /> </div>
                    <div class="newSubscriptionFormSection">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-qty')
                                    }}</span>
                            </mat-label>
                            <input mat-input #quantity name="quantity" type="number" matInput [max]="99" [min]="1" [formControl]="qtyControl" value="1" (change)="setQuantity($event)">
                        </mat-form-field>
                    </div>                    
                    <div class="newSubscriptionFormSection">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-select_payment_source')
                                    }}</span></mat-label>
                            <mat-select
                                [disabled]="loadingSubscription || submittedSubscription || selectedCustomer == undefined || selectedProduct == undefined"
                                [(value)]="paymentSource">
                                <mat-option value="customer" (click)="SetPaymentSource('customer')">
                                    <span *transloco="let t">{{ t('new_subscription_page-customer') }}</span>
                                </mat-option>
                                <mat-option
                                    *ngIf="selectedCustomer == undefined || (selectedCustomer.customerRule.allowDistributorPayment && selectedProductEnabledForDistributorPayment)"
                                    value="distributor" (click)="SetPaymentSource('distributor')">
                                    <span *transloco="let t">{{ t('new_subscription_page-distributor') }}</span>
                                </mat-option>
                                <mat-option
                                    *ngIf="selectedCustomer == undefined || (selectedCustomer.customerRule.isMSOHQ && selectedCustomer.customerRule.allowMSOHQPayment)"
                                    value="msohq" (click)="SetPaymentSource('msohq')">
                                    <span *transloco="let t">{{ t('new_subscription_page-msohq') }}</span>
                                </mat-option>
                                <mat-option 
                                    *ngIf="selectedCustomer == undefined || selectedCustomer.customerRule.allowImporterPayment"
                                    value="importer" (click)="SetPaymentSource('importer')">
                                    <span *transloco="let t">{{ t('new_subscription_page-importer') }}</span>
                                </mat-option>    
                                <mat-option 
                                    *ngIf="selectedCustomer == undefined || selectedCustomer.customerRule.allowImporterPayment"
                                    value="importerEndUser" (click)="SetPaymentSource('importerEndUser')">
                                    <span *transloco="let t">{{ t('new_subscription_page-importer-end-user') }}</span>
                                </mat-option>                                                             
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="this.paymentSource=='msohq'" class="mso-div">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-select_msohq')
                                    }}</span></mat-label>
                            <mat-select [disabled]="loadingSubscription || submittedSubscription" [multiple]="true" [formControl]="selectedMSODependantsControl" #msoSelectedCheck>
                                <mat-select-trigger>
                                    <mat-chip-list>
                                        <mat-chip [disabled]="loadingSubscription || submittedSubscription" *ngFor="let msoDependant of selectedMSODependantsControl.value"
                                            [removable]="true" (removed)="SetMSODependants(msoDependant, chip)" #chip>
                                            {{ msoDependant | customerDisplay }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </mat-select-trigger>
                                <mat-option *ngFor="let msoDependant of msoDependants" [value]="msoDependant" (click)="SetMSODependants(msoDependant, option)" #option>
                                    {{msoDependant | customerDisplay}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="loadingMSODependants">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                            <div>
                                <span *transloco="let t">{{ t('new_subscription_page-total_selected_mso_locations')}} {{ CountTotalSelectedMSODependants() }}</span>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="this.paymentSource=='distributor'">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-select_distributor')
                                    }}</span></mat-label>
                            <mat-select [disabled]="submittedSubscription">
                                <mat-option *ngFor="let distributor of distributors" [value]="distributor"
                                    (click)="SetDistributor(distributor)">
                                    {{distributor | customerDisplay}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="loadingDistributors">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                            <!-- <mat-icon matSuffix>
                                local_mall
                            </mat-icon> -->
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="this.paymentSource=='importerEndUser'">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-select_importer')
                                    }}</span></mat-label>
                            <mat-select [disabled]="submittedSubscription">
                                <mat-option *ngFor="let importer of importers" [value]="importer"
                                    (click)="SetDistributor(importer)">
                                    {{importer | importerDisplay}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="loadingImporters">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                            <!-- <mat-icon matSuffix>
                                local_mall
                            </mat-icon> -->
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="payingCustomerControl.value=='customer' || payingCustomerControl.value=='msohq'">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-payment_type')
                                    }}</span></mat-label>                   
                            <mat-select [disabled]="loadingSubscription || submittedSubscription||billingIds.length<1"
                                [value]=selectedBillingId>
                                <mat-optgroup *transloco="let t"
                                    [label]="t('new_subscription_page-stripe_payment_group')">
                                    <mat-option [value]="''" (click)="SetBillingId('')" [disabled]=manualBillingId [class.active]="true">
                                        <span *transloco="let t">{{ t('new_subscription_page-stripe_payment_type')}}</span>
                                    </mat-option>
                                </mat-optgroup>
                                <mat-optgroup *transloco="let t" [label]="t('new_subscription_page-erp_invoicing')">
                                    <mat-option *ngFor="let id of billingIds" [value]="id" (click)="SetBillingId(id)" [class.active]="true">{{id}}</mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="payingCustomerControl.value=='importer'"
                        class="newSubscriptionFormSection">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-payment_type')
                            }}</span></mat-label>
                            <mat-select [disabled] [value]="'MANUAL'">
                                <mat-option [value]="'MANUAL'" [disabled] (click)="SetBillingId('MANUAL')">
                                    <span *transloco="let t">
                                        <span *transloco="let t">{{ t('new_subscription_page-manual_payment_type')}}</span>
                                    </span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="payingCustomerControl.value!='customer' && payingCustomerControl.value!='msohq' && payingCustomerControl.value!='importer'">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-payment_type_distributor')}}</span></mat-label>
                            <mat-select
                                [disabled]="loadingSubscription || submittedSubscription||billingIds_Distributor.length<1"
                                [value]=selectedBillingId_Distributor>
                                <mat-optgroup *transloco="let t"
                                    [label]="t('new_subscription_page-stripe_payment_group')">
                                    <mat-option [value]="''" (click)="SetBillingId('')" [disabled]=manualBillingId_Distributor [class.active]="true">
                                        <span *transloco="let t">{{ t('new_subscription_page-stripe_payment_type')}}</span>
                                    </mat-option>
                                </mat-optgroup>
                                <mat-optgroup *transloco="let t" [label]="t('new_subscription_page-erp_invoicing')">
                                    <mat-option *ngFor="let id of billingIds_Distributor" [value]="id" [class.active]="true"
                                        (click)="SetBillingId_Distributor(id)">
                                        {{id}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="selectedProduct != undefined && selectedProduct.includesHardware == 'Y'"
                    class="newSubscriptionFormSection">
                    <mat-form-field appearance="fill" class="formField">
                        <mat-label *transloco="let t">{{ t('new_subscription_page-hardware_already_received')
                            }}</mat-label>
                        <mat-select [disabled]="loadingSubscription || submittedSubscription" [value] = "defaultHardwareShipped">
                            <mat-option [value]="'N'" (click)="setHardwareReceived(false)">
                                <span *transloco="let t">
                                    {{ t('new_subscription_page-hardware_already_received-no') }}
                                </span>
                            </mat-option>
                            <mat-option [value]="'Y'" (click)="setHardwareReceived(true)">
                                <span *transloco="let t">
                                    {{ t('new_subscription_page-hardware_already_received-yes') }}
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                    <div *ngIf="productControl.valid && requiredInfo != undefined && requiredInfo.length > 0"
                        class="newSubscriptionFormSection">
                        <div class="newSubscriptionFormSectionTitle">
                            <label class="formLabel"><span *transloco="let t">{{
                                    t('new_subscription_page-additional_required_info') }}</span></label>
                        </div>
                        <div *ngFor="let info of requiredInfo">
                            <mat-form-field appearance="fill" class="formField">
                                <mat-label *transloco="let t">{{ t('new_subscription_page-'+info.label) }}</mat-label>
                                <div *ngIf="info.format==1">
                                    <mat-select [(value)]="info.defaultValue"
                                        [disabled]="!info.editable ||loadingSubscription || submittedSubscription">
                                        <mat-option *ngFor="let option of info.visibleOptions" [value]="option.value"
                                            (click)="SetRequiredInfoDropdown(info, option)">
                                            {{option.value}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div *ngIf="info.format==2">
                                    <input mat-input type="text" matInput [disabled]="loadingSubscription || submittedSubscription" (keyup)="SetRequiredInfo(info, $event)">
                                    <mat-error *ngIf="info.value != '' && !info.value.match(info.pattern)">
                                        <span *transloco="let t">{{
                                            t('new_subscription_page-shipping_phone_number-error') }}</span>
                                    </mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>                  
                    <div class="newSubscriptionFormSection" *ngIf="selectedProduct != undefined && selectedProduct.tcUpload == 'Y'">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-tc_upload')}}</span></mat-label>
                            <mat-select [formControl]="tcUploadControl" >
                                <mat-option value="N" (click)="SetTCUpload('N')">
                                    <span>No</span>
                                </mat-option>
                                <mat-option value="Y" (click)="SetTCUpload('Y')">
                                    <span>Yes</span>
                                </mat-option>                                                                                            
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="showTCUpload">
                        <span *transloco="let t">{{ t('new_subscription_page-tc_upload_label')}} </span>
                        <input type="file" accept=".pdf" (change)="ValidateTCFile($event)">
                        <button mat-raised-button color="primary" class="submitButton" (click)="UploadTCFile($event)"
                            *transloco="let t" [disabled]="!tcFileValid" type="button">{{
                            t('new_subscription_page-tc_upload_button_label') }}</button>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="showTCUpload && tcLinkToFile != ''">
                        <a [href]="tcLinkToFile" target="_blank" *transloco="let t">{{
                            t('new_subscription_page-tc_upload_terms_conditions_link_label') }} </a>
                        <button mat-raised-button color="warn" class="submitButton" (click)="RemoveTCFile()"
                            *transloco="let t" [disabled]="!tcFileValid" type="button">{{
                            t('new_subscription_page-tc_upload_remove_button_label') }}</button>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="selectedProduct != undefined && hardwareReceived == false">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-customer_po')}}</span></mat-label>
                            <input matInput type="text" [formControl]="customerPOControl" (change)="SetcustomerPO($event)"> 
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="selectedProduct != undefined && hardwareReceived == false && selectedCustomer.address.countryCode !== 'US' && selectedCustomer.address.countryCode !== 'CA' && selectedProduct.partNumber.includes('MW')">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-requested_install_date')}}</span></mat-label>
                            <mat-form-field>
                                <input matInput (dateChange)="SetInstallDate($event)" [matDatepicker]="picker" placeholder="Choose a date" [min]="minDate" [formControl]="installDateControl">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker ></mat-datepicker>
                            </mat-form-field>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="selectedProduct != undefined && hardwareReceived == false && (selectedCustomer.address.countryCode == 'US' || selectedCustomer.address.countryCode == 'CA')">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-installer')}}</span></mat-label>
                            <mat-select [formControl]="installerControl" >
                                <mat-option value="PPG Staff" (click)="SetInstaller('PPG Staff')">
                                    <span>PPG Staff</span>
                                </mat-option>
                                <mat-option value="Distributor" (click)="SetInstaller('Distributor')">
                                    <span>Distributor</span>
                                </mat-option>
                                <mat-option value="Corob" (click)="SetInstaller('Corob')">
                                    <span>Corob</span>
                                </mat-option>
                                <mat-option value="Other 3rd Party" (click)="SetInstaller('Other 3rd Party')">
                                    <span>Other 3rd Party</span>
                                </mat-option>                                                                                                
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="selectedProduct != undefined && hardwareReceived == false && selectedProduct.shipDate == 'Yes'">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-requested_ship_date_question')}}</span></mat-label>
                            <mat-select [formControl]="shipDateQuestionControl" >
                                <mat-option value="N" (click)="SetShipDateAnswer('N')">
                                    <span>No</span>
                                </mat-option>
                                <mat-option value="Y" (click)="SetShipDateAnswer('Y')">
                                    <span>Yes</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="showShipDate">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-requested_ship_date')}}</span></mat-label>
                            <mat-form-field>
                                <input matInput (dateChange)="SetShipDate($event)" [matDatepicker]="picker" placeholder="Choose a date" [min]="minDate" [formControl]="shipDateControl">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker ></mat-datepicker>
                            </mat-form-field>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="selectedProduct != undefined && selectedProduct.distSale == 'Yes' && paymentSource != 'distributor'">
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{ t('new_subscription_page-dist_sale')}}</span></mat-label>
                            <mat-select [formControl]="distSaleControl" >
                                <mat-option value="N" (click)="SetDistSale('N')">
                                    <span>No</span>
                                </mat-option>
                                <mat-option value="Y" (click)="SetDistSale('Y')">
                                    <span>Yes</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection">
                        <div class="newSubscriptionFormSectionTitle">
                            <label class="formLabel"><span *transloco="let t">{{ t('new_subscription_page-culture_info')
                                    }}</span></label>
                        </div>
                        <mat-form-field appearance="fill" class="formField">
                            <mat-label><span *transloco="let t">{{
                                    t('new_subscription_page-culture_info-customer_preferred_language')
                                    }}</span></mat-label>
                            <mat-select [disabled]="loadingSubscription || submittedSubscription">
                                <mat-option *ngFor="let lang of availableLangs" [value]="lang"
                                    (click)="SetLanguage(lang.id)">
                                    <span *transloco="let t">
                                        {{ t('language_selector-'+lang.label.toLowerCase()) }}
                                    </span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="formField" *ngIf="selectedProduct!= null && !selectedProduct.unchargedProduct">
                            <mat-label><span *transloco="let t">{{
                                    t('new_subscription_page-culture_info-subscription_currency') }}</span></mat-label>
                            <mat-select [disabled]="loadingSubscription || submittedSubscription">
                                <mat-option *ngFor="let price of currencyOptions" [value]="price"
                                    (click)="SetCurrency(price)">
                                    {{price.currency}} | {{getCurrencySymbols(price)}} {{price.amountDecimal | number:'1.2-2'}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="newSubscriptionFormSection">
                        <button mat-raised-button color="primary" (click)="openContactDialog()" *transloco="let t"
                            [disabled]="loadingSubscription || submittedSubscription" type="button">{{
                            t('new_subscription_page-contact_select') }}</button>
                        <div *ngIf="contactControl.valid">
                            <mat-form-field appearance="fill" class="contactDisplay">
                                <mat-label><span *transloco="let t">{{ t('new_subscription_page-customer_contact')
                                        }}</span></mat-label>
                                <textarea matInput [disabled]="true">{{selectedContact | contactDisplay}}</textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="this.payingCustomerControl.value != 'customer' && this.payingCustomerControl.value != 'msohq' && this.payingCustomerControl.value != 'importer'  && this.payingCustomerControl.value != 'importerenduser'">
                        <button mat-raised-button color="primary" (click)="openDistributorContactDialog()"
                            *transloco="let t" [disabled]="loadingSubscription || submittedSubscription"
                            type="button">{{ t('new_subscription_page-contact_select-distributor') }}</button>
                        <div *ngIf="contactDistributorControl.valid && contactDistributorControl.value!='null'">
                            <mat-form-field appearance="fill" class="contactDisplay">
                                <mat-label><span *transloco="let t">{{ t('new_subscription_page-distributor_contact')
                                        }}</span></mat-label>
                                <textarea matInput
                                    [disabled]="true">{{selectedDistributorContact | contactDisplay}}</textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="newSubscriptionFormSection"
                        *ngIf="!loadingSubscription&&selectedProduct!=undefined&&selectedProduct.scheduled">
                        <!-- <button mat-raised-button color="primary" 
                            class="AdvancedButton" 
                            [disabled]="loadingSubscription" 
                            type="button"
                            *transloco="let t" 
                            matTooltip="{{ t('new_subscription_page-edit_schedule')}}">
                            <mat-icon>
                                settings
                            </mat-icon>
                        </button> -->
                        <div class="newSubscriptionFormSectionTitle">
                            <label class="formLabel"><span *transloco="let t">{{ t('new_subscription_page-details')
                                    }}</span></label>
                        </div>
                        <app-schedule [PriceOptions$]="scheduleSteps" [startingDate]="currentDate"
                            (scheduleSelectedEvent)="setSchedule($event)"></app-schedule>
                    </div>
                    <div class="newSubscriptionFormSection" *ngIf="!submissionComplete; else returnButton">
                        <button mat-raised-button color="primary" class="submitButton" routerLink="/home"
                            *transloco="let t" [disabled]="loadingSubscription" type="button">{{
                            t('new_subscription_page-cancel') }}</button>
                        <button mat-raised-button color="primary" class="submitButton" (click)="submitNewSubscription()"
                            *transloco="let t" [disabled]="!newSubscriptionForm.valid || loadingSubscription"
                            type="button">{{ t('new_subscription_page-submit') }}</button>
                    </div>
                    <ng-template #returnButton>
                        <button mat-raised-button color="primary" class="submitButton" routerLink="/home"
                            *transloco="let t" [disabled]="loadingSubscription" type="button">{{
                            t('new_subscription_page-return_home') }}</button>
                    </ng-template>
                </div>
            </form>
            <div *ngIf="loadingSubscription">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>
</div>
