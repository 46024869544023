<div class="main-div">
	<form>
        <div>
            <div class="resendCSEmailFormSectionTitle">
                <div class="information-section">
                    <div>
                        <label *transloco="let t" class="section-header">{{t('subscription_dashboard_cs_email_page-title')}}</label>
                    </div>
                    <br>
                    <div class="information-details">
                        <div class="information-segment">
                            <label *transloco="let t">{{t('subscription_dashboard_cs_email_page-message')}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="actionButtonsSection" *transloco="let t">
                <button mat-raised-button color="primary" (click)="SendCSEmail()" [disabled]="loading">
                    <span>{{t('subscription_dashboard_cs_email_page-submit_button')}}</span>                    
                </button>&nbsp;
                <button mat-raised-button color="default" [mat-dialog-close]="true" [disabled]="loading">                    
                    <span>{{t('subscription_dashboard_cs_email_page-cancel_button')}}</span>
                </button>
            </div>
            <br>
            <div *ngIf="loading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </form>
</div>