<div class="main-div">
	<form>
		<div class="topButtonSection">
			<div class="menu-button">
				<button mat-mini-fab color="default" [matMenuTriggerFor]="adminMenu" *ngIf="currentUser.admin"><mat-icon>menu</mat-icon></button>
			</div>
			<div>
				<mat-menu #adminMenu="matMenu">
					<button mat-menu-item (click)="ResendCSEmail()">
						<mat-icon>email</mat-icon>
						<span *transloco="let t">{{t('subscription_dashboard_details_page-resend_customer_service_email')}}</span>
					</button>
				</mat-menu>
			</div>
			<div class="close-button">
				<button mat-mini-fab color="default" aria-label="close dialog" mat-dialog-close><mat-icon>close</mat-icon></button>
			</div>
		</div>
		<div id="subDetails" #subDetails>
		<div>
			<label *transloco="let t"
				class="section-header">{{t('subscription_dashboard_details_page-subscription_header')}}</label>
		</div>
		<div class="information-section">
				<mat-expansion-panel multi [expanded]="true">
					<mat-expansion-panel-header>
						<mat-panel-title *transloco="let t" class="section-header">
							{{t('customer_dashboard_details-customer_information_header')}}
						</mat-panel-title>
						<mat-panel-description>
						</mat-panel-description>
					</mat-expansion-panel-header>
				
			<div class="information-details">
				<div class="information-segment">
					<label *transloco="let t">{{t('customer_dashboard_details-customer_id')}}: </label>
					<span># {{data.customer.crmId}}</span>
				</div>
				<div class="information-segment">
					<label *transloco="let t">{{t('customer_dashboard_details-company_name')}}: </label>
					<span>{{data.customer.name}}</span>
				</div>
				<br />
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_page-shipping_address')}}: </label>
					<span>{{data.customer.shippingAddress.line1}} {{data.customer.shippingAddress.city}},
						{{data.customer.shippingAddress.state}} {{data.customer.shippingAddress.postalCode}}</span>
				</div>
				<br />
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_page-billing_address')}}: </label>
					<span>{{data.customer.billingAddress.line1}} {{data.customer.billingAddress.city}},
						{{data.customer.billingAddress.state}} {{data.customer.billingAddress.postalCode}}</span>
				</div>
				<br />
			</div>
		</mat-expansion-panel>
			<mat-expansion-panel multi [expanded]="true" *ngIf="paidBy === 2">
				<mat-expansion-panel-header>
					<mat-panel-title *transloco="let t" class="section-header">
						{{t('subscription_dashboard_details_page-third_party_information')}}
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
			<div class="information-details" *ngIf="paidBy === 2">
				<div class="information-segment">
					<label *transloco="let t">{{t('customer_dashboard_details-customer_id')}}: </label>
					<span># {{data.subscription.crmParentId}}</span>
				</div>
				<div class="information-segment">
					<label *transloco="let t">{{t('customer_dashboard_details-company_name')}}: </label>
					<span>{{data.subscription.parentName}}</span>
				</div>
				<br />
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_page-shipping_address')}}: </label>
					<span>{{data.customer.shippingAddress.line1}} {{data.customer.shippingAddress.city}},
						{{data.customer.shippingAddress.state}} {{data.customer.shippingAddress.postalCode}}</span>
				</div>
				<br />
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_page-billing_address')}}: </label>
					<span>{{data.customer.billingAddress.line1}} {{data.customer.billingAddress.city}},
						{{data.customer.billingAddress.state}} {{data.customer.billingAddress.postalCode}}</span>
				</div>
				<br />
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel multi [expanded]="true">
			<mat-expansion-panel-header>
				<mat-panel-title *transloco="let t" class="section-header">
					{{t('subscription_activation-subscription-information-header')}}
				</mat-panel-title>
				<mat-panel-description>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<div class="information-details">
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_page-subscription_description')}}: </label>
					<span>{{data.subscription.description}}</span>
					<span *ngIf="data.subscription.unchargedSubscription && !showActionButtons">{{data.subscription.description}}</span>
					<mat-form-field appearance="fill" class="formField" *ngIf="data.subscription.unchargedSubscription && showActionButtons">
						<mat-select [(value)]="selectedProduct">
							<mat-optgroup *ngFor="let group of productCategories let i = index" (click)="toggleProductCategory(i)">
								<mat-label class="productGroupLabel"><span *transloco="let t">{{ (group.expanded==true ? '- ' : '+ ') + t('new_subscription_page-product_group-'+group.labelId)}}</span></mat-label>
								<mat-option *ngFor="let product of group.products" [value]="product.id"
									(click)="SetProduct(product.id)" [class.active]="group.expanded">
									{{product.name}}
								</mat-option>
							</mat-optgroup>
						</mat-select>
						<div *ngIf="loadingProducts">
							<mat-progress-bar mode="indeterminate"></mat-progress-bar>
						</div>
					</mat-form-field>					
				</div>
				<br />
				<div class="information-segment" *ngIf="!data.subscription.unchargedSubscription">
					<label *transloco="let t">{{t('upcoming_payments_page-pay_method')}}: </label>
					<span>{{ data.subscription.paymentType | uppercase }}</span>
				</div>
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_page-subscription_status')}}: </label>
					<span>{{ data.subscription.status | uppercase }} </span>
				</div>
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_page-subscription_created')}}: </label>
					<span> {{ data.subscription.created | date:'shortDate' }} </span>
				</div>
				<div class="information-segment" *ngIf="!data.subscription.unchargedSubscription">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-next_payment_date')}}: </label>
					<span>{{ data.subscription.nextPaymentDate | date:'shortDate' }} </span>
				</div>
				<br />
				<div class="information-segment" *ngIf="allowEditDetailsSection">
					<label *transloco="let t">{{t('subscription_dashboard_page-subscription_submitted_by')}}: </label>
						<span> {{ data.subscription.submittedBy }} </span>
				</div>
				<div class="information-segment" *ngIf="!allowEditDetailsSection">
					<mat-form-field appearance="fill" class="formField">
						<mat-label>
							<span *transloco="let t">{{ t('subscription_dashboard_page-subscription_submitted_by')}}</span>
						</mat-label>
						<input mat-input type="text" matInput [(ngModel)]="data.subscription.submittedBy"
								(change)="SetSubmittedBy($event)" [formControl]="submittedByControl">
						 <mat-error *ngIf="submittedByControl.hasError('email') && !submittedByControl.hasError('required')">
							 <span *transloco="let t">{{ t('subscription_dashboard_page-subscription_submitted_by') }}</span>
						 </mat-error>
					</mat-form-field>
				</div>				
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-dist_sale')}}: </label>
					<div class="information-segment" *ngIf="allowEditDetailsSection">
						<a *ngIf="!data.subscription.distSale && allowEditDetailsSection">
							<span>N</span>
						</a>
						<a *ngIf="data.subscription.distSale && allowEditDetailsSection">
							<span> {{ data.subscription.distSale }} </span>
						</a>
					</div>
					<div class="information-segment" *ngIf="!allowEditDetailsSection">
						<mat-select [formControl]="distributorInvolvedControl" [value]="distributorInvolved"> 
							<mat-option value="N" (click)="SetDistributorInvolved('N')">
								<span>No</span>
							</mat-option>
							<mat-option value="Y" (click)="SetDistributorInvolved('Y')">
								<span>Yes</span>
							</mat-option>                                                                                            
						</mat-select>
					</div>					
				</div>
				<br />
				<div class="information-segment">
					<div *ngIf="allowEditDetailsSection && paidBy != 2">
						<label *transloco="let t">{{t('subscription_dashboard_page-subscription_contact')}}: </label>
						<span> {{ data.subscription.contact }} </span>
					</div>
					<div *ngIf="allowEditDetailsSection && paidBy === 2">
						<label *transloco="let t">{{t('subscription_dashboard_page-subscription_customer_contact')}}:
						</label>
						<span> {{ data.subscription.contact }} </span>
					</div>
					<br />
					<div *ngIf="allowEditSubscriptionDetails">
						<button mat-raised-button color="default"
							(click)="ResendNewSubscriptionEmail((paidBy === 2 ? true : false))" *transloco="let t">
							<mat-spinner *ngIf="sendingCustEmail" [diameter]="32"></mat-spinner>
							<span
								*ngIf="!sendingCustEmail">{{t('subscription_dashboard_details_page-resend_initial_email_button')}}</span>
						</button>
					</div>
					<mat-form-field *ngIf="!allowEditDetailsSection" appearance="fill" class="formField">
						<mat-label *ngIf="paidBy != 2"><span *transloco="let t">{{
								t('subscription_dashboard_page-subscription_contact') }}</span></mat-label>
						<mat-label *ngIf="paidBy === 2"><span *transloco="let t">{{
								t('subscription_dashboard_page-subscription_customer_contact') }}</span></mat-label>
						<input mat-input type="text" matInput readonly [(ngModel)]="data.subscription.contact"
							[formControl]="contactEmailControl">
						<mat-error *ngIf="contactEmailControl.hasError('required')">
							<span *transloco="let t">{{ t('subscription_dashboard_page-subscription_contact') }}</span>
						</mat-error>
					</mat-form-field>
					<div class="newSubscriptionFormSection" *ngIf="!allowEditDetailsSection && paidBy != 2">
						<button mat-raised-button color="primary" (click)="openContactDialog('customer')" type="button"
							*transloco="let t">{{ t('new_subscription_page-contact_select') }}</button>
					</div>
					<div class="newSubscriptionFormSection" *ngIf="!allowEditDetailsSection && paidBy === 2">
						<button mat-raised-button color="primary" (click)="openContactDialog('customer')" type="button"
							*transloco="let t">{{ t('subscription_dashboard_page-subscription_customer_contact')
							}}</button>
					</div>
				</div>
				<div *ngIf="paidBy === 2" class="information-segment">
					<div *ngIf="allowEditDetailsSection && paidBy === 2">
						<label *transloco="let t">{{t('subscription_dashboard_page-third_party_contact')}}: </label>
						<span>{{ distributorEmail }}</span>
					</div>
					<div *ngIf="allowEditSubscriptionDetails && paidBy === 2">
						<button mat-raised-button color="default"
							(click)="ResendNewSubscriptionEmail((paidBy === 2 ? false : true))" *transloco="let t">
							<mat-spinner *ngIf="sendingThirdEmail" [diameter]="32"></mat-spinner>
							<span
								*ngIf="!sendingThirdEmail">{{t('subscription_dashboard_details_page-resend_initial_email_button')}}</span>
						</button>
					</div>
					<mat-form-field *ngIf="!allowEditDetailsSection" appearance="fill" class="formField">
						<mat-label><span *transloco="let t">{{ t('subscription_dashboard_page-third_party_contact')
								}}</span></mat-label>
						<input mat-input type="text" matInput readonly [(ngModel)]="distributorEmail"
							[formControl]="distributorEmailControl">
						<mat-error *ngIf="distributorEmailControl.hasError('required')">
							<span *transloco="let t">{{ t('subscription_dashboard_page-third_party_contact') }}</span>
						</mat-error>
					</mat-form-field>
					<div class="newSubscriptionFormSection" *ngIf="!allowEditDetailsSection">
						<button mat-raised-button color="basic" (click)="openContactDialog('thirdParty')" type="button"
							*transloco="let t">{{ t('subscription_dashboard_page-third_party_contact') }}</button>
					</div>
				</div>
				<br />
				<div class="information-segment" *ngIf="data.subscription.erpOrderNumber">					
					<div class="information-segment" *ngIf="allowEditDetailsSection">
						<label *transloco="let t">{{t('subscription_dashboard_page-subscription_erpOrderNumber')}}: </label>						
						<span> {{ data.subscription.erpOrderNumber }} </span>
					</div>
					<div class="information-segment" *ngIf="!allowEditDetailsSection">
						<mat-form-field appearance="fill" class="formField">
							<mat-label>
								<span *transloco="let t">{{ t('subscription_dashboard_page-subscription_erpOrderNumber')}}</span>
							</mat-label>
							<input mat-input type="text" matInput [(ngModel)]="data.subscription.erpOrderNumber"
									(change)="SetERPOrderNbr($event)" [formControl]="erpOrderNumberControl">
							 <mat-error *ngIf="erpOrderNumberControl.hasError('required')">
								 <span *transloco="let t">{{ t('subscription_dashboard_page-subscription_erpOrderNumber') }}</span>
							 </mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="information-segment" *ngIf="data.subscription.erpBillingId">
					<div class="information-segment" *ngIf="allowEditDetailsSection">
						<label *transloco="let t">{{t('subscription_dashboard_details_page-erp_billing_id')}}: </label>
						<span> {{ data.subscription.erpBillingId }} </span>
					</div>
					<div class="information-segment" *ngIf="!allowEditDetailsSection">
						<mat-form-field appearance="fill" class="formField">
							<mat-label>
								<span *transloco="let t">{{ t('subscription_dashboard_page-subscription_erp_billing_id')}}</span>
							</mat-label>
							<input mat-input type="text" matInput [(ngModel)]="data.subscription.erpBillingId"
									(change)="SetBillingId($event)" [formControl]="erpBillingIdControl">
							 <mat-error *ngIf="erpBillingIdControl.hasError('required')">
								 <span *transloco="let t">{{ t('subscription_dashboard_page-subscription_erp_billing_id') }}</span>
							 </mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
			<mat-expansion-panel multi [expanded]="true" *ngIf = "data.subscription.distSale == 'Y' && distributor">
				<mat-expansion-panel-header>
					<mat-panel-title *transloco="let t" class="section-header" >
						{{t('subscription_dashboard_details_page-dist_info_header')}}
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
			<div class="information-details" *ngIf = "data.subscription.distSale == 'Y' && distributor">
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-dist_customer_id')}}:</label>
					<span> {{ distributor.id }} </span>
				</div>
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-dist_name')}}:</label>
					<span> {{ distributor.name }} </span>
				</div>
				<br />
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-dist_address')}}:</label>
					<span> {{distributor.address.line1}} {{distributor.address.city}}, {{distributor.address.state}} {{distributor.address.postalCode}}</span>
				</div>
			</div>
		</mat-expansion-panel>
			<mat-expansion-panel multi [expanded]="true">
				<mat-expansion-panel-header>
					<mat-panel-title *transloco="let t" class="section-header" >
						{{t('subscription_dashboard_details_page-tc_info_header')}}
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
			<div class="information-segment" *ngIf = "!isLoadingTC && data.subscription.status != 'pending' && !tcFileFound">
				<label *transloco="let t">{{t('subscription_dashboard_details_page-tc_not_found')}}</label>
			</div>
			<div class="information-details" *ngIf = "!isLoadingTC && data.subscription.status != 'pending' && tcFileFound">
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-tc_accept_date')}}:</label>
					<span> {{ data.subscription.tcAcceptDate | date:'shortDate' }} </span>
				</div>
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-tc_accept_version')}}:</label>
					<span> {{ tcVersion }} </span>
				</div>
				<br />
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-tc_accepted_by')}}:</label>
					<span> {{ data.subscription.tcUser }} </span>
				</div>
				<br />
				<div class="information-segment">
					<label *transloco="let t">{{t('subscription_dashboard_details_page-tc_link')}}: </label>
					<a [href]="tcLink" target="_blank">{{data.subscription.description}}</a>
					<span></span>
				</div>
			</div>
			<div *ngIf="isLoadingTC" 
			style="display: flex; justify-content: left; align-items: left; background: white;">
			<mat-progress-spinner 
				color="primary" 
				mode="indeterminate">
			</mat-progress-spinner>
			</div>
		</mat-expansion-panel>
			<mat-expansion-panel multi [expanded]="true" *ngIf="showSchedules && allowEditSubscriptionDetails">
				<mat-expansion-panel-header>
					<mat-panel-title *transloco="let t" class="section-header" >
						{{t('subscription_dashboard_page-schedule_details_header')}}
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
			<div class="information-details" *ngIf="showSchedules && allowEditSubscriptionDetails">
				<div class="information-segment">
					<table mat-table [dataSource]="schedule" class="recordsTable" >
						<ng-containter matColumnDef="description">
							<th mat-header-cell *matHeaderCellDef>
								<span *transloco="let t">
									{{ t('subscription_dashboard_page-schedule_details_description') }}
								</span>
							</th>
							<td mat-cell *matCellDef="let record" class="recordsCell">
								{{ record.description }}
							</td>
						</ng-containter>

						<ng-containter matColumnDef="subtotal">
							<th mat-header-cell *matHeaderCellDef>
								<span *transloco="let t">
									{{ t('subscription_dashboard_page-schedule_details_subtotal') }}
								</span>
							</th>
							<td mat-cell *matCellDef="let record" class="recordsCell">
								{{ record.subtotal }}
							</td>
						</ng-containter>

						<ng-containter matColumnDef="startDate">
							<th mat-header-cell *matHeaderCellDef>
								<span *transloco="let t">
									{{ t('subscription_dashboard_page-schedule_details_start_date') }}
								</span>
							</th>
							<td mat-cell *matCellDef="let record" class="recordsCell">
								{{ record.schedule.startDate | date:'fullDate' }}
							</td>
						</ng-containter>

						<ng-containter matColumnDef="endDate">
							<th mat-header-cell *matHeaderCellDef>
								<span *transloco="let t">
									{{ t('subscription_dashboard_page-schedule_details_end_date') }}
								</span>
							</th>
							<td mat-cell class="recordsCell" *matCellDef="let record">
								<span *ngIf="!showActionButtons && allowEditSubscriptionDetails">
									{{ record.schedule.endDate | date:'fullDate' }}
								</span>
 								<mat-form-field *ngIf="showActionButtons && allowUpdateScheduleDate">
									<input id="{{ record.id }}" matInput [disabled] [matDatepicker]="picker" (dateChange)="SetDate($event)" [value]="record.schedule.endDate" placeholder="Choose a date">
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-datepicker #picker ></mat-datepicker>
								</mat-form-field>
							</td>
						</ng-containter>

						<tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"></tr>
					</table>
				 </div>					
				</div>
				<br />
			<div *ngIf="isLoadingSchedule" 
			style="display: flex; justify-content: left; align-items: left; background: white;">
		   <mat-progress-spinner 
			 color="primary" 
			 mode="indeterminate">
		   </mat-progress-spinner>
		</div>
	</mat-expansion-panel>
		<mat-expansion-panel multi [expanded]="true" *ngIf="showAdditionalDetailsSection">
			<mat-expansion-panel-header>
				<mat-panel-title *transloco="let t" class="section-header" >
					{{t('subscription_dashboard_details_page-additional_details')}}
				</mat-panel-title>
				<mat-panel-description>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<div class="information-details" *ngIf="showAdditionalDetailsSection">
				<div class="information-segment">
					<div class="information-segment" *ngIf="!allowEditSubscriptionDetails">
						<label *transloco="let t">{{t('new_subscription_page-distribution')}}: </label>
						<span> {{ data.subscription.distribution }} </span>
					</div>
					<mat-form-field
						*ngIf="showAdditionalDetailsSection && allowEditSubscriptionDetails">
						<mat-label><span *transloco="let t">{{t('new_subscription_page-distribution')}}:
							</span></mat-label>
						<mat-select [disabled]="allowEditDetailsSection" [value]="selectedDistribution.key">
							<mat-option *ngFor="let item of distributions" [value]="item.key"
								(click)="SetDropDownValue(item, 'distribution')">
								{{item.value}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<br />
				<div class="information-segment">
					<div class="information-segment" *ngIf="!allowEditSubscriptionDetails">
						<label *transloco="let t">{{t('new_subscription_page-region')}}: </label>
						<span> {{ data.subscription.region }} </span>
					</div>
					<mat-form-field
						*ngIf="showAdditionalDetailsSection && allowEditSubscriptionDetails">
						<mat-label><span *transloco="let t">{{t('new_subscription_page-region')}}: </span></mat-label>
						<mat-select [disabled]="allowEditDetailsSection" [value]="selectedRegion.key">
							<mat-option *ngFor="let item of regions" [value]="item.key"
								(click)="SetDropDownValue(item, 'region')">
								{{item.value}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<br />
				<div class="information-segment">
					<div class="information-segment" *ngIf="!allowEditSubscriptionDetails">
						<label *transloco="let t">{{t('new_subscription_page-site')}}: </label>
						<span> {{ data.subscription.site }} </span>
					</div>
					<mat-form-field
						*ngIf="showAdditionalDetailsSection && allowEditSubscriptionDetails">
						<mat-label><span *transloco="let t">{{t('new_subscription_page-site')}}: </span></mat-label>
						<mat-select [disabled]="allowEditDetailsSection" [value]="selectedSite.key">
							<mat-option *ngFor="let item of sites" [value]="item.key"
								(click)="SetDropDownValue(item, 'site')">
								{{item.value}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</mat-expansion-panel>
			<br />
		</div>
		<div *ngIf="showLoading">
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
		</div>
		<div class="bottomButtonSection" *transloco="let t">
			<div class="actions">
				<button mat-fab color="primary" class="actionButtons" *ngIf="allowEditDetailsSection && allowEditSubscriptionDetails"
					(click)="SetupButtons(true)">
						<mat-icon>edit</mat-icon> 
						{{t('subscription_dashboard_details_page-edit_button')}}
				</button>&nbsp;		
				<button mat-fab color="primary" class="actionButtons" *ngIf="showActionButtons" (click)="SaveChanges()">
					<mat-icon *ngIf="!savingData">save</mat-icon>
					<mat-spinner *ngIf="savingData" [diameter]="30" class="mat-spinner-color"></mat-spinner>
					<span *ngIf="!savingData"> 
						{{t('subscription_dashboard_details_page-save_button')}}
					</span>
				</button>&nbsp;
				<button mat-fab color="default" class="actionButtons" *ngIf="showActionButtons" (click)="SetupButtons(false)"
					[disabled]="savingData">
					<mat-icon>delete</mat-icon> 
					{{t('subscription_dashboard_details_page-discard_button')}}
				</button>
				<button mat-fab color="warn" class="actionButtons" *ngIf="data.subscription.unchargedSubscription" (click)="ConfirmCancelSubscription()" [disabled]="savingData">
					<span *transloco="let t">{{ t('subscription_dashboard_details_page-cancel_subscription_button') }}</span>
				</button>&nbsp;
				<button mat-fab color="link" class="resubmitOrderButton" *ngIf="showResubmitOracleOrder" (click)="ResubmitERPOracleOrder()" [disabled]="savingData">
					<span *transloco="let t">{{ t('subscription_dashboard_details_page-resubmit_erp_order_button') }}</span>
				</button>&nbsp;				
			</div>
			<div class="pdf">
				<button mat-fab color = "primary" (click)="SavePDF()"><mat-icon>picture_as_pdf</mat-icon></button>
			</div>
		</div>
	</form>
</div>
