<app-toolbar [pageName]="t('admin_page-title')" *transloco="let t"></app-toolbar>
<div>
  <div class="main-div">
 
    <mat-card class="admin-card basic-card mat-elevation-z8" routerLink="/user-associations">
      <mat-card-header class="admin-card-header"></mat-card-header>
      <mat-card-content class="admin-card-content">
        <mat-icon class="card-icon" color="primary">people</mat-icon>
      </mat-card-content>
      <mat-card-footer class="admin-card-footer">
        <mat-card-title  *transloco="let t">{{ t('admin_page-user_association') }}</mat-card-title>
      </mat-card-footer>
    </mat-card>

    <!--
    <mat-card class="admin-card basic-card mat-elevation-z8" routerLink="/payments">
        <mat-card-header class="admin-card-header"></mat-card-header>
        <mat-card-content class="admin-card-content">
          <mat-icon class="card-icon" color="primary">insert_drive_file</mat-icon>
        </mat-card-content>
        <mat-card-footer class="admin-card-footer">
          <mat-card-title  *transloco="let t">{{ t('admin_page-resubmit_erp') }}</mat-card-title>
        </mat-card-footer>
    </mat-card>
    -->

      <mat-card class="admin-card basic-card mat-elevation-z8" routerLink="/update-schedule">
        <mat-card-header class="admin-card-header"></mat-card-header>
        <mat-card-content class="admin-card-content">
          <mat-icon class="card-icon" color="primary">access_time</mat-icon>
        </mat-card-content>
        <mat-card-footer class="admin-card-footer">
          <mat-card-title  *transloco="let t">{{ t('admin_page-reset_schedule') }}</mat-card-title>
        </mat-card-footer>
      </mat-card>

      <mat-card class="admin-card basic-card mat-elevation-z8" routerLink="/past-due-subscriptions">
        <mat-card-header class="admin-card-header"></mat-card-header>
        <mat-card-content class="admin-card-content">
          <mat-icon class="card-icon" color="primary">insert_drive_file</mat-icon>
        </mat-card-content>
        <mat-card-footer class="admin-card-footer">
          <mat-card-title  *transloco="let t">{{ t('admin_page-past_due_subscriptions') }}</mat-card-title>
        </mat-card-footer>
      </mat-card>        
  </div>
</div>
<ng-template #loadingDiv>
  <!-- <div class="main-div">
    <div *ngIf="spinnerWhileLoading">
      <mat-spinner class="admin-card" color="accent"></mat-spinner>
    </div>
  </div> -->
</ng-template>
