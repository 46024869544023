<ng-container *transloco="let t">
<div *ngIf="loading; else dialogDiv" >
    <mat-progress-bar mode="indeterminate" value="40"></mat-progress-bar>
</div>
<ng-template #dialogDiv>
    <h1 mat-dialog-title >{{ t('home_page-tax_validation-title') }}</h1>
    <h4>{{customer.customerName}} ({{customer.crmId}})</h4>
    <div mat-dialog-content>
        <div>
            <!-- <mat-form-field appearance="standard"  class="formField">
                <mat-label>{{ t('home_page-tax_validation-location_country') }}</mat-label>
                <mat-select [formControl]="countryControl" autofocus>
                    <mat-option *ngFor="let country of countrySelection" [value]="country">
                        <span>{{t('general-country_'+country)}}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="countryControl.hasError('required')">
                    <span>{{ t('home_page-tax_validation-select_valid_country') }}</span>
                    </mat-error>
            </mat-form-field> -->
            <mat-form-field appearance="standard"  class="formField">
                <mat-label>{{ t('home_page-tax_validation-tax_id_type') }}</mat-label>
                <mat-select [formControl]="taxIdTypeControl" (selectionChange)="setTax($event)">
                    <mat-option *ngFor="let tax of taxTypes" [value]="tax.tax_Enum">
                        <span>{{t('general-tax_type_'+tax.tax_Enum)}}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="taxIdTypeControl.hasError('required')">
                    <span>{{ t('home_page-tax_validation-select_valid_tax_id_type') }}</span>
                    </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard"  class="formField">
                <mat-label> {{ taxIdLabel }}</mat-label>
                <input mat-input type="text" 
                matInput 
                [formControl]="taxIdControl">
                <mat-error *ngIf="taxIdControl.hasError('required')">
                    <span>{{ t('home_page-tax_validation-select_valid_tax_id') }}</span>
                    </mat-error>
            </mat-form-field>
            <div *ngIf="unVerifiedErrorMessage">
                <span class="errorMessage"> {{t('home_page-tax_validation-verified_error')}}</span>
            </div>
            <div *ngIf="unexpectedErrorMessage">
                <span class="errorMessage"> {{t('home_page-tax_validation-unexpected_error')}}</span>
            </div>
        </div>
        <div class="verifySpinnerDiv">
            <div *ngIf="waiting" class="verifySpinner">
                <mat-progress-spinner mode="determinate" [value]="progressDots"></mat-progress-spinner>
            </div><br/>
            <div *ngIf="waiting" class="verifySpinnerText">
                <span>{{t('home_page-tax_validation-verifying')}}...</span> 
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div>
            <button mat-button [disabled]="!taxIdForm.valid || waiting" (click)="submitClick()">
                {{ t('home_page-tax_validation-submit')}}
            </button>
        </div>
    </div>
</ng-template>
</ng-container>