import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionDashboardRecord } from 'src/app/pages/subscriptions-dashboard-page/interfaces/subscription-dashboard-record.module';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { PaymentService } from 'src/app/shared/payment/services/payment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Invoice } from 'src/app/shared/payment/interfaces/Invoice.model';
import { InvoiceResubmitConfirmationComponent } from './invoice-resubmit-confirmation/invoice-resubmit-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-subscription-invoices',
  templateUrl: './admin-subscription-invoices.component.html',
  styleUrls: ['./admin-subscription-invoices.component.scss']
})
export class AdminSubscriptionInvoicesComponent implements OnInit {

  customerTitle: string = "";
  records: Invoice[] = [];
  displayedPage: Invoice[] = [];
  pageSize: number = 10;
  pageIndex: number = 0;
  reachedEnd: boolean = false;
  displayedColumns: string[] = ['invoiceId', 'number','subTotal','tax','total','currency','status','createdDate','erpOrderNumber'];
  showLoading: boolean = false;
  
  constructor(public dialogRef: MatDialogRef<AdminSubscriptionInvoicesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: SubscriptionDashboardRecord,
    private paymentService: PaymentService,
    private errorHandler: ErrorHandlerService,
    private confirmationDialog: MatDialog) { }

  ngOnInit(): void {
    this.GetInvoices();
  }

  GetInvoices(){
    this.showLoading = true; 
    this.paymentService.getInvoicesBySubscriptionId(this.data.subscription.id).subscribe({
      next: invoices=>{
        this.records = [];
        invoices.forEach(invoice =>{
          this.records.push(invoice);
        });
        this.getCurrentPage();
        this.showLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.showLoading = false;
        this.errorHandler.handleError(err);
      }
    });
  }

  getCurrentPage(): void {
    var startIndex = this.pageIndex * this.pageSize;
    var endIndex = this.pageIndex * this.pageSize + this.pageSize;
    this.displayedPage = this.records.slice(startIndex, endIndex > this.records.length ? this.records.length : endIndex);
  }


  ReSendOrder(row: Invoice){
    const dialogRef = this.confirmationDialog.open(InvoiceResubmitConfirmationComponent,
      {
        width: '22vw',
        height: '14vw',
        data: row,
        autoFocus: false
       });
   }
}
