import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { UserCustomerRelationship } from 'src/app/shared/user/interfaces/user.customer.relationship.model';
import { LoginFlowService } from 'src/app/shared/login-check/login-flow.service';
import { UserService } from 'src/app/shared/user/services/user.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { AdminAddUserAssociationComponent } from '../admin-add-user-association/admin-add-user-association.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { AdminDeleteUserAssociationComponent } from '../admin-delete-user-association/admin-delete-user-association.component';

@Component({
  selector: 'app-admin-user-association-page',
  templateUrl: './admin-user-association-page.component.html',
  styleUrls: ['./admin-user-association-page.component.scss']
})
export class AdminUserAssociationPageComponent implements OnInit {
  currentUser: User | null = null;
  records: UserCustomerRelationship[] = [];
  displayedPage: UserCustomerRelationship[] = [];
  pageSize: number = 10;
  pageIndex: number = 0;
  reachedEnd: boolean = false;
  displayedColumns: string[] = ['checked', 'userEmail','customerId','createdDate'];
  showLoading: boolean = false;  
  private readonly _destroying$ = new Subject<void>();
  showSearchLoading = false;
  filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  filterValue: string = "";
  deleteButtonDisabled: boolean = true;
  showLoadingButtons: boolean = false;

  constructor(
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private userService: UserService,
    private router: Router,
    private exportDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.checkLogin();
    this.GetAllUsers();
    this.getUserFilter();
  }

  checkLogin(): void{
    this.loginFlowService.getUser().subscribe({
      next: user=>{
        this.currentUser = user;
        if (!user.admin)
        {
          // Redirect user, is not an admin
          const err = new HttpErrorResponse({ status: 401, error: "Sorry, you don't have access to this feature", statusText: "Sorry, you don't have access to this feature",url: "" });
          this.errorHandler.handleError(err);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  GetAllUsers()
  {
    this.showLoading = true;
    this.records = [];
    this.displayedPage = [];
    this.userService.getAllUsers().pipe(takeUntil(this._destroying$)).subscribe({
      next: users=>{
        if (users.length > 0){
          users.forEach(user => {
            user.authorizations.forEach(auth =>{
              this.records.push({customerId: auth.customerId, userEmail: user.email, createdDate: user.createdDate, checked: false});
            });
          });
        }
        else{
          this.records = [];
        }
        this.getCurrentPage();
        this.showLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.showLoading = false;
        this.errorHandler.handleError(err);
      }
    });
  }


  handlePageEvent(e: any) {
    if (this.pageSize != e.pageSize) {
      this.pageIndex = 0;
      this.pageSize = e.pageSize;
    } else {
      this.pageIndex = e.pageIndex;
    }
    this.getCurrentPage();
  }

  getCurrentPage(): void {
    var startIndex = this.pageIndex * this.pageSize;
    var endIndex = this.pageIndex * this.pageSize + this.pageSize;
    this.displayedPage = this.records.slice(startIndex, endIndex > this.records.length ? this.records.length : endIndex);
  }

  CheckAll(e: any)
  {
    this.displayedPage.forEach(user => {
      user.checked = e.checked;
    });

    this.deleteButtonDisabled = !e.checked;
  }

  SetCheckedRecord(e: any, userEmail: string, customerId: string)
  {
    this.displayedPage.filter(x => x.userEmail == userEmail && x.customerId == customerId).forEach(x =>
      {
        x.checked = e.checked;
      });

      this.deleteButtonDisabled = this.displayedPage.filter(x => x.checked).length == 0;
  }

  getUserFilter(){
    this.filterValue$.pipe(
      debounceTime(3000),
      distinctUntilChanged(),
      filter(value=> value.length >=2),
    ).subscribe(filterValue=>{
      this.showSearchLoading = false;
      this.filterValue = filterValue;
      this.records = this._userFilter(filterValue).slice();
      this.getCurrentPage();
    })
   
  }

  applyUserFilter(event: Event){
    this.showSearchLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValue$.next(filterValue.trim().toLowerCase());
  }

  private _userFilter(value: string): UserCustomerRelationship[] {
    if(value.length <3){
      return this.records;
    }
    else{
      const filterValue = value.toLowerCase();

      return this.records.filter(record => record.userEmail.toLowerCase().includes(filterValue) 
        || record.customerId.includes(filterValue));
    }
  }

  ExportList()
  {
    this.showLoadingButtons = true;
    var a = document.createElement("a");
    var contents = "userEmail,customerId,createdDate\n\"";

    this.records.forEach((record, index) => {
      contents = contents
        + record.userEmail + "\",\""
        + record.customerId + "\",\""
        + record.createdDate + "\",\""
        + "\"\n\"";
    });

    a.href = URL.createObjectURL(new Blob([contents], { type: 'text/csv' }));
    a.download = "UserCustomerAssociations.csv";

    a.click();
    this.showLoadingButtons = false;
  }

  AddAssociation(){
    const dialogRef = this.exportDialog.open(AdminAddUserAssociationComponent, {
      width: '30vw',
      closeOnNavigation: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.GetAllUsers();
        this.getUserFilter();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  DeleteAssociations(){
    const dialogRef = this.exportDialog.open(AdminDeleteUserAssociationComponent, {
      data: this.displayedPage.filter(x => x.checked),
      width: '60vw',
      closeOnNavigation: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.GetAllUsers();
        this.getUserFilter();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
