import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NewSubscriptionPageComponent } from './pages/new-subscription-page/new-subscription-page/new-subscription-page.component';
import { IncompleteSubscriptionsPageComponent } from './pages/incomplete-subscriptions-page/incomplete-subscriptions-page/incomplete-subscriptions-page.component';
import { CustomerDashboardPageComponent } from './pages/customer-dashboard-page/customer-dashboard-page.component';
import { ActivateSubscriptionPageComponent } from './pages/activate-subscription-page/activate-subscription-page.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { ToolbarComponent } from './shared/navigation/components/toolbar/toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerDisplayPipe } from './shared/customer/pipes/customer-display.pipe';
import { ImporterDisplayPipe } from './shared/customer/pipes/importer-display.pipe';
import { ContactBuildPipe } from './shared/customer/pipes/contact-build.pipe';
import { ContactSelectorComponent } from './pages/new-subscription-page/contact-selector/contact-selector.component';
import { ContactDisplayPipe } from './shared/customer/pipes/contact-display.pipe';
import { AddressDisplayPipe } from './shared/customer/pipes/address-display.pipe';
import { SubscriptionActivationComponent } from './shared/subscription/components/subscription-activation/subscription-activation.component';
import { PriceDisplayPipe } from './shared/subscription/pipes/price-display.pipe';
import { PageNotFoundComponent } from './pages/error-pages/page-not-found/page-not-found.component';
import { ActivateSubscriptionDialogComponent } from './pages/incomplete-subscriptions-page/activate-subscription-dialog/activate-subscription-dialog.component';
import { b2cAuthModule } from './b2c-auth.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { PageErrorComponent } from './pages/error-pages/page-error/page-error.component';
import { CustomerAssociationComponent } from './shared/login-check/customer-association/customer-association.component';
import { UpcomingPaymentsPageComponent } from './pages/upcoming-payments-page/upcoming-payments-page/upcoming-payments-page.component';
import { LoginCheckComponent } from './shared/login-check/login-check.component';
import { TaxValidationComponent } from './shared/login-check/tax-validation/tax-validation.component';
import { PaymentMethodComponent } from './shared/login-check/payment-method/payment-method.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { CustomerDashboardDetailsPageComponent } from './pages/customer-dashboard-page/customer-dashboard-details-page/customer-dashboard-details-page.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LanguageSelectorComponent } from './shared/language/components/language-selector/language-selector.component';
import { LoginFlowService } from './shared/login-check/login-flow.service';
import { ToolbarMenuComponent } from './shared/navigation/components/toolbar/toolbar-menu/toolbar-menu.component';
import { ContactHelpComponent } from './shared/navigation/components/contact-help/contact-help.component';
import { SubscriptionsDashboardPageComponent } from './pages/subscriptions-dashboard-page/subscriptions-dashboard-page.component';
import { LoadingComponent } from './shared/navigation/components/loading/loading.component';
import { ScheduleComponent } from './pages/new-subscription-page/schedule/schedule.component';
import { AcceptTermsAndConditionsComponent } from './shared/login-check/accept-terms-and-conditions/accept-terms-and-conditions.component';
import { StripeAccountSelectorComponent } from './shared/stripe-accounts/components/stripe-account-selector/stripe-account-selector.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccountInterceptor } from './AccountInterceptor';
import { SubscriptionsDashboardDetailsPageComponent } from './pages/subscriptions-dashboard-page/subscriptions-dashboard-details-page/subscriptions-dashboard-details-page/subscriptions-dashboard-details-page.component';
import { SubscriptionsCancelPageComponent } from './pages/subscriptions-dashboard-page/subscriptions-dashboard-cancel-subscription.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { SubscriptionsDashboardExportComponent } from './pages/subscriptions-dashboard-page/subscriptions-dashboard-export/subscriptions-dashboard-export.component'; 
import { CurrencySymbolPipe } from './shared/subscription/pipes/currency-symbol.pipe';
import { AccountsPageComponent } from './pages/accounts-page/accounts-page.component';
import { AccountPageComponent } from './pages/accounts-page/account-page/account-page.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { SubscriptionsDashboardCsEmailComponent } from './pages/subscriptions-dashboard-page/subscriptions-dashboard-cs-email/subscriptions-dashboard-cs-email.component';
import { AdminUserAssociationPageComponent } from './pages/admin-page/admin-user-association-page/admin-user-association-page.component';
import { AdminAddUserAssociationComponent } from './pages/admin-page/admin-add-user-association/admin-add-user-association.component';
import { AdminDeleteUserAssociationComponent } from './pages/admin-page/admin-delete-user-association/admin-delete-user-association.component'
import { ConfirmErpOrderResubmitPageComponent } from './pages/subscriptions-dashboard-page/subscriptions-dashboard-details-page/confirm-erp-order-resubmit-page/confirm-erp-order-resubmit-page.component'
import { AdminPastDueComponent } from './pages/admin-page/admin-past-due/admin-past-due.component';
import { AdminSubscriptionInvoicesComponent } from './pages/admin-page/admin-subscription-invoices/admin-subscription-invoices.component';
import { InvoiceResubmitConfirmationComponent } from './pages/admin-page/admin-subscription-invoices/invoice-resubmit-confirmation/invoice-resubmit-confirmation.component';
import { UserAssociationConfirmationComponent } from './pages/new-subscription-page/user-association-confirmation/user-association-confirmation.component';
import { UpdateScheduleComponent } from './pages/admin-page/update-schedule/update-schedule.component';
import { UpdateSubscriptionScheduleComponent } from './pages/admin-page/update-subscription-schedule/update-subscription-schedule.component';
import { AddSubscriptionPhaseComponent } from './pages/admin-page/add-subscription-phase/add-subscription-phase.component';

//for testing
//import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
//import { DataService } from './test/data.services';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    NewSubscriptionPageComponent,
    IncompleteSubscriptionsPageComponent,
    CustomerDashboardPageComponent, //depreciated
    ActivateSubscriptionPageComponent,
    ToolbarComponent,
    CustomerDisplayPipe,
    ImporterDisplayPipe,
    ContactBuildPipe,
    ContactSelectorComponent,
    ContactDisplayPipe,
    AddressDisplayPipe,
    SubscriptionActivationComponent,
    PriceDisplayPipe,
    PageNotFoundComponent,
    ActivateSubscriptionDialogComponent,
    PageErrorComponent,
    CustomerAssociationComponent,
    UpcomingPaymentsPageComponent,
    LoginCheckComponent,
    TaxValidationComponent,
    PaymentMethodComponent,
    TermsAndConditionsComponent,
    CustomerDashboardDetailsPageComponent, //depreciated
    LanguageSelectorComponent,
    ToolbarMenuComponent,
    ContactHelpComponent,
    SubscriptionsDashboardPageComponent,
    LoadingComponent,
    ScheduleComponent,
    AcceptTermsAndConditionsComponent,
    StripeAccountSelectorComponent,
    SubscriptionsDashboardDetailsPageComponent,
    SubscriptionsCancelPageComponent,    
    CheckoutPageComponent,
    SubscriptionsDashboardExportComponent,
    CurrencySymbolPipe,
    AccountsPageComponent,
    AccountPageComponent,
    AdminPageComponent,
    SubscriptionsDashboardCsEmailComponent,
    AdminUserAssociationPageComponent,
    AdminAddUserAssociationComponent,
    AdminDeleteUserAssociationComponent,
    ConfirmErpOrderResubmitPageComponent,
    AdminPastDueComponent,
    AdminSubscriptionInvoicesComponent,
    InvoiceResubmitConfirmationComponent,
    UserAssociationConfirmationComponent,
    UpdateScheduleComponent,
    UpdateSubscriptionScheduleComponent,
    AddSubscriptionPhaseComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    TranslocoRootModule,
    ReactiveFormsModule,
    //HttpClientInMemoryWebApiModule.forRoot(DataService, { passThruUnknownUrl: true, delay: 1500 }), //for testing
    b2cAuthModule,
    PdfViewerModule,
    MatExpansionModule
  ],
  providers: [
    CurrencySymbolPipe,
    CustomerDisplayPipe,
    ImporterDisplayPipe,
    ContactBuildPipe,
    AddressDisplayPipe,
    PriceDisplayPipe,
    LoginFlowService, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccountInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
