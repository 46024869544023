import { BootstrapOptions, Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Price } from 'src/app/shared/subscription/interfaces/price.model';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AddSubscriptionPhaseComponent } from '../add-subscription-phase/add-subscription-phase.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-update-subscription-schedule',
  templateUrl: './update-subscription-schedule.component.html',
  styleUrls: ['./update-subscription-schedule.component.scss']
})
export class UpdateSubscriptionScheduleComponent implements OnInit {

  displayedColumns: string[] = ['description', 'startDate', 'endDate', 'trial'];
  dataSource: Price[] = [];
  phasesDropDown: Price[] = [];
  hasSchedule: boolean = true;
  showSchedules: boolean = false;
  showLoading: boolean = false;
  trial: boolean = false;
  index: number = 0;

  constructor(public dialogRef: MatDialogRef<UpdateSubscriptionScheduleComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private errorHandler: ErrorHandlerService,
    private subscriptionService: SubscriptionService,
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private addPhaseDialog: MatDialog) { }

  ngOnInit(): void {
    this.phasesDropDown = this.data.phases.filter((x: { currency: string; }) => x.currency.toLowerCase() == this.data.subscriptionData.customer.currency);
    this.GetSchedules();
  }

  GetSchedules(){    
    this.dataSource = [];
    var records: Price[] = [];    
    if (this.data.subscriptionData.subscription.scheduleId != null)
    {
      this.showLoading = true;
      this.subscriptionService.getSubscriptionSchedule(this.data.subscriptionData.subscription.scheduleId).subscribe({
        next: schedules =>
        {
          schedules.forEach(schedule => {
            records.push(schedule);
          });
          this.showSchedules = true;
          this.dataSource = records;
          this.hasSchedule = this.dataSource.length > 0 ? true : false;
          this.showLoading = false;
        },
        error: (err: HttpErrorResponse) =>
        {
            this.showLoading = false;
            this.errorHandler.handleError(err);
        }
      });

      if(this.data.subscriptionData.subscription.status.toLowerCase() == "trialing"){
        this.trial = true;
        this.SetTrial(this.trial);
      }
    
    }
  }

  SetDate(event: MatDatepickerInputEvent<Date>, rowIndex: number): void {
    if (event.value == null) {
      this._snackBar.open(this.translocoService.translate('schedule_details_end_date_error'), this.translocoService.translate('general_message_dismiss'))
      return;
    }
    var newEndDate: Date = event.value ?? new Date();
    var rowEndDate = new Date(this.dataSource[rowIndex].schedule.endDate);
    var calculatedEndDate = new Date(newEndDate);

    for (var i = (rowIndex + 1); i <= this.dataSource.length - 1; i++) {
      this.dataSource[rowIndex].schedule.endDate = newEndDate
      this.dataSource[rowIndex+1].schedule.startDate = newEndDate

      if(this.dataSource[i].schedule.startDate >= this.dataSource[i].schedule.endDate){
        this.dataSource[i].schedule.endDate = new Date(calculatedEndDate.setMonth(calculatedEndDate.getMonth() + 1));
        this.dataSource[i+1].schedule.startDate = calculatedEndDate

      }
    }
  }

  SetTrial(trial: boolean){
    this.trial = trial;
  }

  AddPhase(){
    var startDate = this.data.subscriptionData.subscription.scheduleId != null ?
                    new Date(this.dataSource[this.dataSource.length - 1].schedule.endDate) :
                    new Date();
    const dialogRef = this.addPhaseDialog.open(AddSubscriptionPhaseComponent,
      {
        width: '30vw',
        height: '25vw',       
        data: { 
          subscriptionId: this.data.subscriptionData.subscription.id,
          startDate: startDate,
          phases: this.phasesDropDown,
          scheduleId: this.data.subscriptionData.subscription.scheduleId ?? "",
          subscriptionSchedule: this.dataSource,
          customerId: this.data.subscriptionData.customer.id
          },
        autoFocus: false
       });
       dialogRef.afterClosed().subscribe({
        next: result => {
          if (result)
          {
            this._snackBar.open(this.translocoService.translate('add_subscription_phase-add-success'), this.translocoService.translate('general_message_dismiss'))
            this.GetSchedules();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
  }

  Save(){
    this.showLoading = true;
    this.subscriptionService.addPhaseToSubscription(this.dataSource, 
                                                    this.data.subscriptionData.subscription.scheduleId, 
                                                    this.data.subscriptionData.subscription.id,
                                                    this.trial,
                                                    this.data.subscriptionData.customer.id).subscribe({
      next: result => {
        this.showLoading = false;
        if (result){
          this._snackBar.open(this.translocoService.translate('add_subscription_phase-update-success'), this.translocoService.translate('general_message_dismiss'));
          this.GetSchedules();
          //this.dialogRef.close(true);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
        this.showLoading = false;
      }
    }); 
  }
}
