import { Component,Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { UserService } from 'src/app/shared/user/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserCustomerRelationship } from 'src/app/shared/user/interfaces/user.customer.relationship.model';

@Component({
  selector: 'app-admin-delete-user-association',
  templateUrl: './admin-delete-user-association.component.html',
  styleUrls: ['./admin-delete-user-association.component.scss']
})
export class AdminDeleteUserAssociationComponent implements OnInit {

  loading: boolean = false;
  displayedColumns: string[] = ['userEmail', 'customerId'];
  dataSource = this.data; 

  constructor(public dialogRef: MatDialogRef<AdminDeleteUserAssociationComponent>,     
    @Inject(MAT_DIALOG_DATA)
    public data: UserCustomerRelationship[],
    private errorHandler: ErrorHandlerService,
    private userService: UserService,
    private exportDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
  }

  DeleteUserAssociation(){
    this.loading = true;
    this.data.forEach(x =>
      {
        this.userService.deleteCustomerId(x.userEmail, x.customerId)
        .subscribe({
          next: result => {
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
            this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'));
          }
        });
      });
      this.dialogRef.close();
  }
}
