<h1 mat-dialog-title *transloco="let t" class="title">{{ t('admin_delete_user_association_page-title') }}</h1>
<div class="main-div">
    <mat-card class="filter-card">
        <mat-card-content>
            <label *transloco="let t">{{t('admin_delete_user_association_page-confirmation_message')}}: </label>
            <br>
            <table mat-table [dataSource]="dataSource" class="recordsTable" >

                <ng-containter matColumnDef="userEmail">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('admin_delete_user_association_page-email_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.userEmail }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="customerId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('admin_delete_user_association_page-customer_column') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customerId }}
                    </td>
                </ng-containter>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"></tr>
            </table>
        </mat-card-content>        
        <mat-card-content class="actionButtons">
            <button mat-raised-button color="warn" type="button" class="navButton" (click)="DeleteUserAssociation()" [disabled]="loading">
                <mat-icon class="toolbar-icon">delete</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_delete_user_association_page-delete_button') }}</span>
            </button>&nbsp;
            <button mat-raised-button color="default" type="button" class="navButton" [mat-dialog-close]="true" [disabled]="loading">
                <mat-icon class="toolbar-icon">close</mat-icon>&nbsp;
                <span  *transloco="let t">{{ t('admin_delete_user_association_page-cancel_button') }}</span>
            </button>            
        </mat-card-content>
    </mat-card>
    <br>
    <div *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>