import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Price } from 'src/app/shared/subscription/interfaces/price.model';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
  selector: 'app-add-subscription-phase',
  templateUrl: './add-subscription-phase.component.html',
  styleUrls: ['./add-subscription-phase.component.scss']
})
export class AddSubscriptionPhaseComponent implements OnInit {

  loading: boolean = false;
  selectedPrice!: Price;
  endDateControl = new FormControl<Date>(new Date(), Validators.required);
  selectedPhaseControl = new FormControl<string>('', Validators.required);
  endDate = new Date();
  price!: Price;

  constructor(public dialogRef: MatDialogRef<AddSubscriptionPhaseComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private subscriptionService: SubscriptionService,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void {
    var calculatedEndDate = new Date(this.data.startDate);
    this.endDateControl.setValue(new Date(calculatedEndDate.setMonth(calculatedEndDate.getMonth() + 1)));
  }

  SetPhase(price: Price){
    this.price = price;
    this.price.schedule.startDate = this.data.startDate;
    this.price.schedule.endDate = this.endDateControl.value ?? new Date();
  }

  AddPhase(){
    this.loading = true;
    this.data.subscriptionSchedule.push(this.price);
    this.subscriptionService.addPhaseToSubscription(this.data.subscriptionSchedule, this.data.scheduleId, this.data.subscriptionId, false, this.data.customerId).subscribe({
      next: result => {
        this.loading = false;
        if (result){
          this.dialogRef.close(true);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
        this.loading = false;
      }
    }); 
  }
}
