<ng-container *transloco="let t">
    <div *ngIf="loading; else dialogDiv">
        <mat-progress-bar mode="indeterminate" value="40"></mat-progress-bar>
    </div>
    <ng-template #dialogDiv>
        <h1 mat-dialog-title>{{ t('home_page-payment_method-title') }}</h1>
        <div mat-dialog-content class="dialogContent">
            <div class="messageLabel">
                <span>{{ t('home_page_payment_method-message') }}</span>
            </div>
            <div class="mat-elevation-z8 table-div">
                <div class="table-div-inner">

                    <table mat-table [dataSource]="checkouts" class="recordsTable">
                        <ng-containter matColumnDef="customerId">
                            <th mat-header-cell *matHeaderCellDef><span>{{
                                    t('home_page_payment_method-customer_id') }}</span></th>
                            <td mat-cell *matCellDef="let checkout" class="recordsCell">{{ checkout.crmCustomerId ??
                                ""}}</td>
                        </ng-containter>

                        <ng-containter matColumnDef="customerName">
                            <th mat-header-cell *matHeaderCellDef><span>{{
                                    t('home_page_payment_method-customer_name') }}</span></th>
                            <td mat-cell *matCellDef="let checkout" class="recordsCell">
                                {{ checkout.customerName ?? ""}}
                            </td>
                        </ng-containter>

                        <ng-containter matColumnDef="parent">
                            <th mat-header-cell *matHeaderCellDef><span>{{
                                    t('home_page_payment_method-distributor') }}</span></th>
                            <td mat-cell *matCellDef="let checkout" class="recordsCell">
                                {{checkout.parentName ?? "" }} ({{checkout.crmParentId ?? ""}})
                            </td>
                        </ng-containter>

                        <ng-containter matColumnDef="subscriptionDescription">
                            <th mat-header-cell *matHeaderCellDef><span>{{
                                    t('home_page_payment_method-subscription') }}</span></th>
                            <td mat-cell *matCellDef="let checkout" class="recordsCell">
                                {{ checkout.subscriptionDescription ?? ""}}
                            </td>
                        </ng-containter>

                        <ng-containter matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef><span>{{
                                    t('home_page_payment_method-price') }}</span></th>
                            <td mat-cell *matCellDef="let checkout" class="recordsCell">
                                {{checkout.price.currency}} | {{getCurrencySymbols(checkout)}}
                                {{checkout.price.amountDecimal | number:'1.2-2'}}
                            </td>
                        </ng-containter>

                        <ng-containter matColumnDef="dueDate">
                            <th mat-header-cell *matHeaderCellDef><span>{{
                                    t('home_page_payment_method-dueDate') }}</span></th>
                            <td mat-cell *matCellDef="let checkout" class="recordsCell">
                                <a>{{checkout.dueDate | date:'fullDate'}} </a>
                            </td>
                        </ng-containter>
                        <ng-containter matColumnDef="url">
                            <th mat-header-cell *matHeaderCellDef><span>{{
                                    t('home_page_payment_method-checkoutLink') }}</span></th>
                            <td mat-cell *matCellDef="let checkout" class="recordsCell">
                                <button mat-raised-button color="accent"
                                    (click)="continueClick(checkout.url, checkout.termsAndConditions?.fullPath)"
                                    class="actionButton">
                                    {{ t('home_page-payment_method-add_payment_link')}}
                                </button>
                            </td>
                        </ng-containter>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"></tr>
                    </table>
                </div>
            </div>
            <!-- <div class="actionButtons">
            <button mat-raised-button color="accent"  (click)="refreshClick()" class="actionButton">
                {{ t('home_page-payment_method-check_payment')}}
            </button>
        </div> -->
        </div>
    </ng-template>
</ng-container>