import { Component,Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';

@Component({
  selector: 'app-confirm-erp-order-resubmit-page',
  templateUrl: './confirm-erp-order-resubmit-page.component.html',
  styleUrls: ['./confirm-erp-order-resubmit-page.component.scss']
})
export class ConfirmErpOrderResubmitPageComponent implements OnInit {
  loading: boolean = false;
  disableResubmitButton: boolean = false;
  newOrderNumberMessage: string = ""
  orderNbr: string = ""

  constructor(public dialogRef: MatDialogRef<ConfirmErpOrderResubmitPageComponent>,     
    @Inject(MAT_DIALOG_DATA)
    public data: string,
    private subscriptionService: SubscriptionService,
    private errorHandler: ErrorHandlerService,
    private exportDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
  }

  ResubmitOrder(){
    this.loading = true;
    this.subscriptionService.resubmitOracleOrder(this.data)
    .subscribe({
      next: result => {
        if (result.indexOf(this.data) < 0){
          this.disableResubmitButton = true;
          this.newOrderNumberMessage = this.translocoService.translate('confirm_erp_order_resubmit_page-resubmit_success') + result;
          this.orderNbr = result;
        }
        else{          
          this._snackBar.open(this.translocoService.translate('confirm_erp_order_resubmit_page-resubmit_error') + result, this.translocoService.translate('general_message_dismiss'));
        }
        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
        this.loading = false;
        this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'));
      }
    });
  }

  CloseForm(){
    this.dialogRef.close(this.orderNbr);
  }
}
